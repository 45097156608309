<script setup>
import { ref, onMounted } from 'vue'
import DateTimeHelper from '../../js/helper/DatetimeHelper'
import { useDashboardStore } from '../../js/store/dashboard';

const dashboardStore = useDashboardStore()
const dt = ref(new Date())

// auto time update real time
setInterval(() => {
  dt.value = new Date()
}, 1000)

onMounted(async () => {
  await dashboardStore.getTotals()
})
</script>
<template>
  <div class="w-full bg-white rounded-md p-8">
    <div class="flex items-center gap-12">
      <div class="flex items-end border-b-2 border-primary py-1">
        <div>{{ DateTimeHelper.format(dt, 'YYYY') }}</div>
        <div>/</div>
        <div class="text-2xl">{{ DateTimeHelper.format(dt, 'MM') }}</div>
        <div>/</div>
        <div class="text-2xl">{{ DateTimeHelper.format(dt, 'DD(ddd)') }}</div>
        <div class="text-5xl font-bold">{{ DateTimeHelper.format(dt, 'HH:mm') }}</div>
        <div class="text-2xl font-bold w-12">:{{ DateTimeHelper.format(dt, 'ss') }}</div>
      </div>
    </div>
  </div>
</template>
