<script setup>
import { ref,  getCurrentInstance } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel} from '@headlessui/vue'
import { useDatabaseStore } from '../../js/store/database'
import { useRoute} from 'vue-router'

const $route = useRoute()
const databaseStore = useDatabaseStore()
const $root = getCurrentInstance()?.appContext.config.globalProperties
const isOpen = ref(false)
const parent = ref({})
const flagEdit = ref(0)

const open = async (item) => {
  isOpen.value = true
  parent.value = JSON.parse(JSON.stringify(item));
  if(item.id) {
    flagEdit.value = 1;
  } else {
    flagEdit.value = 0;
    parent.value.status = 1;
    parent.value.address = parent.value.students[0].address;
  }
  
}
const close = () => {
  isOpen.value = false
}

const update = async() => {
  $root.overlay.loading = true
  try {
    const response = await axios.post('/api/update-family', parent.value);
    if(response.status == 200) {
      $root.push.success(response.data.message)
      databaseStore.getItem($route.params.id);
      close();
    } else {
      $root.push.error(response.data.message)
    }
  } catch (error) {
    console.error('Error adding user:', error);
  }
  $root.overlay.loading = false
}

defineExpose({ open, close })
</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-lg transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all p-4 border-t-2 border-t-secondary"
            >
              <div class="flex flex-col gap-2">
                <div class="flex w-full gap-2">
                  <div class="flex-auto">
                    <label for="name" class="text-sm text-gray-500">氏名（漢字）</label>
                    <input v-model="parent.kanji_name" type="text" class="w-full border border-gray-300 rounded p-2" />
                  </div>
                  <div class="flex-auto">
                    <label for="name" class="text-sm text-gray-500">氏名（ローマ字）</label>
                    <input v-model="parent.romaji_name" type="text" class="w-full border border-gray-300 rounded p-2" />
                  </div>
                </div>
                <div class="flex w-full gap-2">
                  <div class="flex-auto">
                    <label for="name" class="text-sm text-gray-500">状態</label>
                    <select v-model="parent.status" class="w-full border border-gray-300 rounded p-2">
                        <option value="0">無効</option>
                        <option value="1">有効</option>
                      </select>
                  </div>
                  <div class="flex-auto">
                    <label for="name" class="text-sm text-gray-500">続柄</label>
                    <select v-model="parent.relationship" class="w-full border border-gray-300 rounded p-2">
                        <option value="Father">父</option>
                        <option value="Mother">母</option>
                        <option value="Other">他</option>
                      </select>
                  </div>
                </div>

                <div class="grid grid-cols-2 gap-2">
                  <div class="flex flex-col">
                    <label for="name" class="text-sm text-gray-500">携帯番号</label>
                    <input v-model="parent.phone" type="text"  class="w-full border border-gray-300 rounded p-2" />
                  </div>

                  <div class="flex flex-col">
                    <label for="name" class="text-sm text-gray-500">メールアドレス</label>
                    <input v-model="parent.email" type="text"  class="w-full border border-gray-300 rounded p-2" />
                  </div>
                </div>

                <div class="flex flex-col">
                  <label for="name" class="text-sm text-gray-500">現住所</label>
                  <input v-model="parent.address" type="text"  class="w-full border border-gray-300 rounded p-2" />
                </div>

                <!-- cancel + save button -->
                <div class="flex justify-center gap-2 mt-6">
                  <button class="px-4 py-2 border rounded" type="button" @click.prevent="close">キャンセル</button>
                  <button class="px-4 py-2 text-white bg-primary rounded" @click.prevent="update()">{{flagEdit? '更新' : '登録する'}}</button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
