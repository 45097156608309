<script setup>
import { PlusCircleIcon, MagnifyingGlassIcon, ChevronDownIcon, } from '@heroicons/vue/24/outline';
import { useResource } from '../../../js/store/resource';
const resourceStore = useResource()
</script>
<template>
  <div class="flex items-center border-t-2 border-t-secondary border-b border-b-gray-200 bg-gray-100/50 p-1 justify-between sticky top-0 backdrop-blur transition-all delay-75 duration-300 w-full">
    <div class="flex items-center gap-2 justify-between w-full">
      <div class="flex items-center gap-1">
        <ChevronDownIcon 
          class="w-4 h-4 text-gray-500 transition duration-300 delay-75 ease-in-out cursor-pointer"
          :class="{ 'rotate-180': !resourceStore.expanded }"
          @click.prevent="resourceStore.expanded = !resourceStore.expanded" 
        />
        <h3 class="flex items-center">
          <span class="text-primary-500 font-semibold">リソース</span>
          <span class="text-xs text-gray-500">({{ resourceStore.items.length }})</span>
        </h3>
        <!-- filter by name -->
        <div class="w-32 relative">
          <MagnifyingGlassIcon class="absolute top-1/2 left-2 transform -translate-y-1/2 w-4 h-4 text-gray-500" />
          <input type="text" v-model="resourceStore.search" class="w-full h-6 border border-gray-200 rounded-md p-1 text-xs pl-7" placeholder="名前" />
        </div>
      </div>
      
      <button title="新規作成" class="group" @click.prevent="$emit('add')">
        <PlusCircleIcon class="w-6 h-6 text-primary group-hover:scale-110 group-hover:fill-primary group-hover:text-white transition-all duration-100 delay-75 ease-in-out" />
      </button>
    </div>
  </div>
</template>
