<script setup>
import { getCurrentInstance } from 'vue'
import DateTimeHelper from '../../js/helper/DatetimeHelper';
import { useImportStore } from '../../js/store/import';
import TablePagination from './TablePagination.vue';

const $root = getCurrentInstance()?.appContext.config.globalProperties
const importStore = useImportStore()
</script>
<template>
  <div class="w-full bg-white rounded-md flex flex-col gap-2">
    <table class="w-full table">
      <thead class="border-t-2 border-t-secondary border-b border-b-gray-200 bg-gray-100 text-xs">
        <tr>
          <th v-for="header in importStore.headers" class="font-normal p-2 text-left">{{ header.text }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="importStore.items.length">
          <tr v-for="item in importStore.items" class="border-b border-b-gray-200">
            <td class="p-2">{{ item.id }}</td>
            <td 
              class="p-2 font-bold text-pblue cursor-pointer hover:opacity-80 hover:underline" 
            >
              <router-link :to="`/import`">
                {{ item.file_name }}
              </router-link>
            </td>
            <td class="p-2">{{ DateTimeHelper.format(item.created_at, 'YYYY/MM/DD(ddd)　HH:mm') }}</td>
            <td class="p-2">{{ item.user.name }}</td>
            <td class="p-2 max-w-40 truncate text-ellipsis">
              {{ item.status == 1 ? '完了' : '失敗' }}
            </td>
            <td class="p-2">
              {{ item.note=='null' ? '' : item.note}}
            </td>
          </tr>
        </template>
        <tr v-else-if="!$root.overlay.loading">
          <td class="p-2 text-center text-xs text-grey" :colspan="importStore.headers.length">
            データがありません
          </td>
        </tr>
      </tbody>
    </table>

    <!-- pagination -->
    <TablePagination :total="importStore.total" v-model:currentPage="importStore.currentPage"  :perPage="importStore.perPage" :onPageChange="importStore.getItems" />
  </div>
</template>
