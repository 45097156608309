<script setup>
import { ref,  getCurrentInstance } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue'
import { ChevronRightIcon, CalendarIcon } from '@heroicons/vue/24/outline';
import { useClubStore } from '../../js/store/club';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const $root = getCurrentInstance()?.appContext.config.globalProperties
const clubStore = useClubStore()

const isOpen = ref(false)
const open = () => {
  isOpen.value = true
}
const close = () => {
  isOpen.value = false
}

const save = async () => {
  // validate
  if (!clubStore.member.name) {
    $root.push.error('名前を入力してください')
    return
  }

  if (!clubStore.member.phone) {
    $root.push.error('電話番号を入力してください')
    return
  }

  $root.overlay.loading = true
  try {
    close()
    const response = await clubStore.addGuest()

    if (response.status === 'error') {
      $root.push.error(response.message)
    } else {
      $root.push.success('メンバーを追加しました')
    }
    
  } catch (error) {
    $root.push.error('メンバーの追加に失敗しました')
  }
  $root.overlay.loading = false
}

defineExpose({ open, close })
</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-xl transform bg-white text-left align-middle shadow-xl transition-all rounded-xl p-4"
            >
              <!-- add member to club #A -->
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900 flex items-center gap-2 mb-4"
              >
                <div class="px-2 py-1 rounded-md bg-gray-200">{{ clubStore.item.name }}</div>
                <ChevronRightIcon class="w-4 h-4 text-gray-400" />
                <!-- add guest member -->
                メンバー追加
              </DialogTitle>
              <div class="flex flex-col gap-2">
                <!-- name -->
                <div class="flex flex-col">
                  <label>名前</label>
                  <input 
                    type="text" 
                    class="px-2 py-1 border border-gray-200 rounded" 
                    v-model="clubStore.member.name"
                  />
                </div>

                <div class="flex items-center gap-4">
                  <!-- gender -->
                  <div class="flex flex-col w-1/3">
                    <label>性別</label>
                    <select 
                      class="px-2 py-1 border border-gray-200 rounded" 
                      v-model="clubStore.member.gender"
                    >
                      <option value="male">男性</option>
                      <option value="female">女性</option>
                      <option value="other">その他</option>
                    </select>
                  </div>

                  
                  <!-- date of birth -->
                  <div class="flex flex-col w-2/3">
                    <label>生年月日</label>
                    <VueDatePicker
                      locale="ja"
                      auto-apply
                      format="yyyy-MM-dd"
                      input-class-name="px-2 py-1 border border-gray-200 rounded"
                      v-model="clubStore.member.dob"
                    />
                  </div>
                </div>

                <div class="flex items-center gap-4">
                  <!-- phone -->
                  <div class="flex flex-col w-1/2">
                    <label>電話番号</label>
                    <input 
                      type="text" 
                      class="px-2 py-1 border border-gray-200 rounded" 
                      v-model="clubStore.member.phone"
                    />
                  </div>

                  <!-- email -->
                  <div class="flex flex-col w-1/2">
                    <label>メールアドレス</label>
                    <input 
                      type="email" 
                      class="px-2 py-1 border border-gray-200 rounded" 
                      v-model="clubStore.member.email"
                    />
                  </div>
                </div>

                <!-- note -->
                <div class="flex flex-col">
                  <label>備考</label>
                  <textarea 
                    class="px-2 py-1 border border-gray-200 rounded" 
                    v-model="clubStore.member.note"
                  ></textarea>
                </div>

                <div class="flex items-center gap-1 cursor-pointer">
                  <input 
                    type="checkbox" 
                    class="form-checkbox rounded text-primary w-5 h-5" 
                    id="auto-generate-qrcode"
                    v-model="clubStore.autoGenerateQrcode"
                  />
                  <label for="auto-generate-qrcode">メンバー追加後、QRコードを自動生成する</label>
                </div>

                <!-- expired_at -->
                <div class="flex flex-col">
                  <label for="expired_at">有効期限</label>
                  <div class="max-w-40">
                    <VueDatePicker 
                      v-model="clubStore.member.expired_at" 
                      locale="ja"
                      format="yyyy-MM-dd"
                      :enable-time-picker="false"
                      auto-apply
                      teleport
                    >
                      <template #input-icon>
                        <CalendarIcon class="ml-2 w-4 h-4" />
                      </template>
                      <template #dp-input="{ value }">
                        <input 
                          type="text" 
                          :value="value" 
                          class="border border-gray-200 rounded pl-8 pr-2 py-1 max-w-40"
                        />
                      </template>
                    </VueDatePicker>
                  </div>
                </div>

                <div class="flex items-center gap-4 justify-end mt-6">
                  <!-- cancel -->
                  <button 
                    class="px-4 py-2 bg-white border rounded hover:opacity-80 transition duration-300 delay-75 ease-in-out"
                    @click.prevent="close"
                  >
                    キャンセル
                  </button>
                  <!-- add guest -->
                  <button 
                    class="px-4 py-2 bg-primary text-white rounded hover:opacity-80 transition duration-300 delay-75 ease-in-out"
                    @click.prevent="save"
                  >
                    保存
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
