<script setup>
import { onMounted, getCurrentInstance } from 'vue'
import { useDatabaseStore } from '../../../js/store/database'
import TableDatabase from '../../components/TableDatabase.vue'

const $root = getCurrentInstance()?.appContext.config.globalProperties
const databaseStore = useDatabaseStore()

onMounted(() => {
  getItems();
})
const genQRCode = async() => {
  $root.overlay.loading = true
  try {
    const response = await databaseStore.genQRCode();
    if(response.status == 200) {
      $root.push.success(response.data.message)
      getItems();
    } else {
      $root.push.error('QRコードの生成が失敗されました。')
    }
  } catch (error) {
    console.error('Error adding user:', error);
  }
  $root.overlay.loading = false
}

const getItems = async() => {
  $root.overlay.loading = true
  await databaseStore.getItems()
  $root.overlay.loading = false
}
</script>
<template>
  <div class="w-full bg-white rounded-md p-4">
    <div class="flex flex-col gap-2 w-full">
      <div class="flex items-end justify-between">
        <div class="w-full flex gap-2 items-end flex-wrap">
          <div class="flex flex-col">
            <label>年生</label>
            <select v-model="databaseStore.grade" class="px-2 py-1 border border-gray-200 rounded-md">
              <option value="">すべて</option>
              <option v-for="grade in databaseStore.gradeItems">{{ grade }}</option>
            </select>
          </div>

          <div class="flex flex-col">
            <label>組</label>
            <select v-model="databaseStore.class" class="px-2 py-1 border border-gray-200 rounded-md">
              <option value="">すべて</option>
              <option v-for="cls in databaseStore.classItems">{{ cls }}</option>
            </select>
          </div>

          <div class="flex flex-col">
            <label>生徒ID</label>
            <input v-model="databaseStore.student_code" type="text" class="min-w-48 px-2 py-1 border border-gray-200 rounded-md" />
          </div>
          <div class="flex flex-col">
            <label>生徒名</label>
            <input v-model="databaseStore.name" type="text" class="min-w-48 px-2 py-1 border border-gray-200 rounded-md" />
          </div>
          <div class="flex flex-col">
            <label>保護者名</label>
            <input v-model="databaseStore.parentName" type="text" class="min-w-48 px-2 py-1 border border-gray-200 rounded-md" />
          </div>
          <div class="flex flex-col">
            <label>保護者電話</label>
            <input v-model="databaseStore.phone" type="text" class="min-w-48 px-2 py-1 border border-gray-200 rounded-md" />
          </div>
          <div class="flex flex-col">
            <button class="px-4 py-1.5 bg-primary shadow text-white rounded hover:opacity-80" @click.prevent="databaseStore.currentPage = 1; getItems()">
              検索 
            </button>
          </div>
        </div>
        <div>
          <button class="px-0 py-1.5 bg-sky-500 shadow text-white rounded hover:opacity-80 min-w-40" @click.prevent="genQRCode()">
            QRコード 更新  
          </button>
        </div>
        <div class="ml-2">
          <button class="px-0 py-1.5 bg-orange-700 shadow text-white rounded hover:opacity-80 min-w-40">
            QRコード配信  
          </button>
        </div>
        </div>
      <TableDatabase />
    </div>
  </div>
</template>
