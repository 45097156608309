<script setup>
import { computed } from 'vue'
import { XMarkIcon, PlusIcon, ChevronRightIcon } from '@heroicons/vue/24/outline'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { useEmailSendStore } from '../../js/store/email-send'

const emailSendStore = useEmailSendStore()

const changeHandler = (level, grade, name) => {
  emailSendStore.syncTargets(level, grade, name)
}

// computed for check disabled or checked status
const isAll = computed(() => {
  if (! emailSendStore.item.targets ||  emailSendStore.item.targets.length == 0) {
    return false
  }
  
  return emailSendStore.item.targets.some(target => target.key === 'all')
})

const isChecked = (level, grade, name) => {
  if (! emailSendStore.item.targets ||  emailSendStore.item.targets.length == 0) {
    return false
  }

  return emailSendStore.item.targets.some(target => {
    if (name) {
      return target.key === `${level}-${grade}-${name}`
    } else if (grade) {
      return target.key === `${level}-${grade}`
    } else {
      return target.key === level
    }
  })
}
</script>
<template>
  <div class="flex items-center">
    <div class="flex-none w-44 p-1 h-full">
      配信対象
    </div>
    <div class="grow h-full">
      <div class="flex flex-wrap gap-2 items-center bg-white p-1 h-full">
        <div>
          <Popover v-slot="{ open }" class="relative">
            <PopoverButton class="rounded border border-primary p-1 group">
              <PlusIcon class="w-4 h-4 text-primary transition duration-300 delay-75 ease-in-out group-hover:scale-110" />
            </PopoverButton>

            <transition
              enter-active-class="transition duration-200 ease-out"
              enter-from-class="translate-y-1 opacity-0"
              enter-to-class="translate-y-0 opacity-100"
              leave-active-class="transition duration-150 ease-in"
              leave-from-class="translate-y-0 opacity-100"
              leave-to-class="translate-y-1 opacity-0"
            >
              <PopoverPanel v-slot="{ close }"
                class="absolute bg-white shadow-lg rounded-b border-t-2 border-primary mt-1 z-10 w-[500px]"
              >
                <div class="flex flex-col gap-4 w-full">
                  <div class="flex items-center justify-between bg-graysp px-4 py-2">
                    <h3 class="text-md font-bold">配信対象を追加</h3>

                    <div class="flex items-center gap-2">
                      <button 
                        class="rounded border border-primary px-2 py-px text-primary hover:bg-primary hover:text-white transition duration-300 ease-in-out"
                        @click.prevent="emailSendStore.resetTargets"
                      >
                        リセット
                      </button>

                      <!-- close -->
                      <button 
                        class="rounded border border-gray-200 px-2 py-px transition duration-300 ease-in-out"
                        @click.prevent="close"
                      >
                        閉じる
                      </button>
                    </div>
                    
                  </div>
                  
                  <div class="px-4 flex flex-col mt-2 mb-4 gap-4">
                    <!-- all -->
                    <label class="flex items-center gap-1 cursor-pointer">
                      <input 
                        type="checkbox" 
                        class="w-4 h-4" 
                        value="all" 
                        @change="changeHandler('all')"
                        :checked="isAll"
                      >
                      全学年
                    </label>

                    <div 
                      class="grid gap-4" 
                      :class="'grid-cols-' + Object.keys(emailSendStore.targets).length"
                    >
                      <div 
                        v-for="(schoolLvl) in emailSendStore.targets"
                        class="flex flex-col gap-2 max-h-[500px] overflow-y-auto"
                      >
                        <div class="flex items-center gap-1">
                          <ChevronRightIcon 
                            class="w-4 h-4 cursor-pointer hover:scale-110" 
                            :class="{
                              'rotate-90': schoolLvl.open
                            }"
                            @click.prevent="schoolLvl.open = !schoolLvl.open"
                          />
                          <label class="flex items-center gap-1 cursor-pointer">
                            <input 
                              type="checkbox" 
                              class="w-4 h-4" 
                              :value="schoolLvl.name" 
                              @change="changeHandler(schoolLvl.name)"
                              :checked="isAll || isChecked(schoolLvl.name)"
                              :disabled="isAll"
                            >
                            {{ schoolLvl.name }}
                          </label>
                        </div>

                        <div v-show="schoolLvl.open" class="flex flex-col gap-2">
                          <div 
                            v-for="grade in schoolLvl.childs" 
                            class="flex flex-col gap-1 pl-4"
                          >
                            <div class="flex items-center gap-1">
                              <ChevronRightIcon 
                                class="w-4 h-4 cursor-pointer hover:scale-110" 
                                :class="{
                                  'rotate-90': grade.open
                                }"
                                @click.prevent="grade.open = !grade.open"
                              />
                              <label  class="flex items-center gap-1 cursor-pointer">
                                <input 
                                  type="checkbox" 
                                  class="w-4 h-4" 
                                  :value="grade.name" 
                                  @change="changeHandler(schoolLvl.name, grade.name)"
                                  :checked="isAll || isChecked(schoolLvl.name) || isChecked(schoolLvl.name, grade.name)"
                                  :disabled="isAll || isChecked(schoolLvl.name)"
                                >
                                {{ grade.name }}
                              </label>
                            </div>

                            <div v-show="grade.open" class="flex flex-col gap-1">
                              <label v-for="className in grade.childs" class="flex items-center gap-1 cursor-pointer pl-10">
                                <input 
                                  type="checkbox" 
                                  class="w-4 h-4" 
                                  :value="className.name" 
                                  @change="changeHandler(schoolLvl.name, grade.name, className.name)"
                                  :checked="isAll || isChecked(schoolLvl.name) || isChecked(schoolLvl.name, grade.name) || isChecked(schoolLvl.name, grade.name, className.name)"
                                  :disabled="isAll || isChecked(schoolLvl.name) || isChecked(schoolLvl.name, grade.name)"
                                >
                                {{ className.name }}
                              </label>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </PopoverPanel>
            </transition>
          </Popover>
        </div>

        <div 
          v-for="target in emailSendStore.item.targets" 
          class="rounded border border-primary px-2 py-px flex items-center gap-1 group bg-primary/10"
        >
          {{ target.value }}
          <XMarkIcon 
            class="w-4 h-4 text-error cursor-pointer hover:opacity-80 group-hover:scale-110 transition duration-300 ease-in-out"
            title="削除"
            @click.prevent="emailSendStore.removeTarget(target.key)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
