import { defineStore } from 'pinia'
import APPJSH from '../helper/ConstantHelper'
import DateTimeHelper from '../helper/DatetimeHelper'

export const useEmailSendStore = defineStore('email-send', {
  state: () => ({
    templates: [],
    item: {
      targets: [],
      type: APPJSH.EMAIL_SEND_TYPES.NOW,        // {今すぐ配信, 予約配信}
      date: '',
      attachments: [],
      subject: '',
      content_text: '',
      content_html: '',
      template_id: 0,
    },
    error: '',
    targets: {},
  }),
  actions: {
    resetItem() {
      this.item = {
        targets: [],
        type: APPJSH.EMAIL_SEND_TYPES.NOW,
        date: '',
        attachments: [],
        subject: '',
        content_text: '',
        content_html: ' ',
        template_id: 0,
      }

      this.error = ''
    },
    async getTargets() {
      // get email targets
      const response = await axios.get('/api/email-targets')

      if (response.status === 200) {
        this.targets = response.data.data
      }
    },
    async getTemplates() {
      // get email templates
      const response = await axios.get('/api/email-templates')

      if (response.status === 200) {
        this.templates = response.data.data
      }
    },
    updateTemplate() {
      if (!this.item.template_id) {
        this.item.template_id = 0
        this.item.content_text = ''
        this.item.content_html = ' '
        return
      }

      // get email template by id
      const template = this.templates.find(template => template.id === this.item.template_id)
      if (template) {
        this.item.content_text = template.content_text
        this.item.content_html = template.content_html
        if (!this.item.content_html) {
          this.item.content_html = ' '
        }
      }
    },
    send() {
      const data = Object.assign({}, this.item)

      if (data.type === APPJSH.EMAIL_SEND_TYPES.NOW) {
        data.date = ''
      } else {
        if (data.date && data.date !== '') {
          data.date = DateTimeHelper.format(data.date, 'YYYY-MM-DD HH:mm:ss')
        }
      }

      // post attachment files to server
      const formData = new FormData()
      // i want to use if ($request->hasFile('attachments')) { in laravel
      // how to send multiple files in here?
      // append to attachments: files
      if (data.attachments.length > 0) {
        data.attachments.forEach(file => {
          formData.append('attachments[]', file)
        })
      }

      // formData.append('targets', data.targets.join(',').toString())
      const targetKeys = data.targets.map(target => target.key)
      formData.append('targets', targetKeys.join(',').toString())
      formData.append('type', data.type)
      formData.append('date', data.date)
      formData.append('subject', data.subject.trim())
      formData.append('content_text', data.content_text.trim())
      formData.append('content_html', data.content_html.trim())

      return axios.post('/api/send-email', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },
    syncTargets(level, grade, name) {
      if (level === 'all') {
        if (!this.item.targets.find(target => target.key === 'all')) {
          this.item.targets = [{key: 'all', value: '全学年'}]
        } else {
          this.item.targets = this.item.targets.filter(target => target.key !== 'all')
        }
        return
      }
      
      // add val if not exists
      const val = name ? `${level}-${grade}-${name}` : (grade ? `${level}-${grade}` : level)
      if (!this.item.targets.find(target => target.key === val)) {
        this.item.targets.push({
          key: level && grade && name 
            ? `${level}-${grade}-${name}`
            : (level && grade ? `${level}-${grade}` : level),
          value: level && grade && name ? `${level} ・ ${grade} ・ ${name}` : (level && grade ? `${level} ・ ${grade}` : level)
        })
      } else {
        // remove val if exists
        if (name) {
          this.item.targets = this.item.targets.filter(target => target.key !== `${level}-${grade}-${name}`)
        }
        if (grade) {
          this.item.targets = this.item.targets.filter(target => target.key !== `${level}-${grade}`)
        }
        if (level) {
          this.item.targets = this.item.targets.filter(target => target.key !== level)
        }
      }

      // do sync values again
      // by default, if all levels are selected, then all grades are selected
      // if level is selected, then all grades of that level are selected
      // if grade is selected, then all names of that grade are selected
      // example: have key 中学部-中1 and 中学部-中1-3組 -> remove 中学部-中1-3組
      let targets = this.item.targets
      if (!targets.length) {
        return
      }

      for (let i = 0; i < targets.length; i++) {
        const target = targets[i]
        const keys = target.key.split('-')
        if (keys.length < 3) {
          targets = targets.filter(t => !t.key.startsWith(`${target.key}-`))
        }
      }

      this.item.targets = targets
    },
    removeTarget(key) {
      this.item.targets = this.item.targets.filter(target => target.key !== key)
    },
    resetTargets() {
      this.item.targets = []
    }
  },
  getters: {
    // get text of 配信日時設定
    getSendTypeText() {
      // 予約配信：2024/06/10　8：00
      if (this.item.type === APPJSH.EMAIL_SEND_TYPES.NOW) {
        return '今すぐ配信'
      }

      return `予約配信：${DateTimeHelper.format(this.item.date, 'YYYY/MM/DD(ddd)　HH:mm')}`
    },
    // parse text in textarea to html
    parseTextToHtml() {
      if (!this.item.content_text || !this.item.content_text.trim()) {
        return ''
      }

      return this.item.content_text.replace(/\n/g, '<br>')
    }
  }
})
