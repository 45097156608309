import { defineStore } from 'pinia'
import DateTimeHelper from '../helper/DatetimeHelper'

export const useDatabaseStore = defineStore('database', {
  state: () => ({
    // list
    headers: [
      {
        text: 'ID',
        field: 'id'
      },
      {
        text: '生徒ID',
        field: 'student_code'
      },
      {
        text: '生徒名',
        field: 'name'
      },
      {
        text: '学校',
        field: 'school_level'
      },
      {
        text: '学年',
        field: 'grade'
      },
      {
        text: 'クラス',
        field: 'class_name'
      },
      {
        text: '住所',
        field: 'address'
      },
      {
        text: 'QR_Code',
        field: 'qr_code_url'
      }
    ],
    // family relationship: name, mobile, email, relationship
    childrenHeaders: [
      {
        field: 'name',
        text: '名前'
      },
      {
        field: 'mobile',
        text: '携帯番号'
      },
      {
        field: 'email',
        text: 'メールアドレス'
      },
      {
        field: 'relationship',
        text: '続柄'
      },
      {
        field: 'qr_code_url',
        text: 'QR_Code'
      }
    ],
    items: [],
    item: {},
    brdString: '',
    subStudent: [],
    total: 0,
    currentPage: 1,
    perPage: 50,
    grade: '',
    class: '',
    student_code: '',
    name: '',
    parentName: '',
    phone: '',
    gradeItems: ['小1', '小2', '小3', '小4', '小5', '小6', '中1', '中2', '中3'],
    classItems: ['1組', '2組', '3組', '4組', '5組'],
  }),
  actions: {
    async getItems() {
      const response = await axios.get(`/api/students?per_page=${this.perPage}&page=${this.currentPage}&grade=${this.grade}&class=${this.class}&student_code=${this.student_code}&name=${this.name}&parentName=${this.parentName}&phone=${this.phone}`);
      if (response.status !== 200) {
        this.errors.response = 'エラーが発生しました。しばらくしてからもう一度お試しください。'
        return
      }
      this.items = response.data.data;
      this.total = response.data.total;
      this.currentPage = response.data.current_page;
    },

    async getItem(id) {
      const response = await axios.get(`/api/students/${id}`);
      if (response.status !== 200) {
        this.errors.response = 'エラーが発生しました。しばらくしてからもう一度お試しください。'
        return
      }
      this.item = response.data;
      this.brdString = DateTimeHelper.convert2JpDate(this.item.date_of_birth);
      this.subStudent = this.item.families[0].students.filter((std)=> std.id != this.item.id);
    },

    genQRCode() {
      return axios.post(`/api/students/generate-qr-codes?per_page=${this.perPage}&page=${this.currentPage}&grade=${this.grade}&class=${this.class}&name=${this.name}&parentName=${this.parentName}&phone=${this.phone}`);
    }
  }
})
