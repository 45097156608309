import { defineStore } from 'pinia'
import APPJSH from '../helper/ConstantHelper'
import DateTimeHelper from '../helper/DatetimeHelper'

export const useEmailHistoryStore = defineStore('email-history', {
  state: () => ({
    headers: [
      {
        text: 'ID',
        field: 'id'
      },
      {
        text: '配信日時',
        field: 'date'
      },
      {
        text: '配信対象',
        field: 'target'
      },
      {
        text: '配信ステータス',
        field: 'status'
      },
      {
        text: 'メール件名',
        field: 'subject'
      },
    ],
    items: [],
    page: 1,
    total: 0,
    perPage: 16,
  }),
  actions: {
    async getItems() {
      if (this.page > Math.ceil(this.total / this.perPage)) {
        this.page = Math.ceil(this.total / this.perPage)
      }

      if (this.page < 1) {
        this.page = 1
      }

      // get email history
      const response = await axios.get('/api/email-history', {
        params: {
          page: this.page
        }
      })

      if (response.status === 200) {
        this.items = response.data.data
        this.total = response.data.total
      }
    },
    getSendTypeText(item) {
      // 予約配信：2024/06/10　8：00
      if (item.type === APPJSH.EMAIL_SEND_TYPES.NOW) {
        return '今すぐ配信'
      }

      return `予約配信：${DateTimeHelper.format(item.send_at, 'YYYY/MM/DD(ddd)　HH:mm')}`
    },
    // parse text in textarea to html
    parseTextToHtml(value) {
      return value.replace(/\n/g, '<br>')
    },
    getItem(id) {
      return this.items.find((item) => item.id === id)
    },
  },
})
