import { defineStore } from 'pinia'
import dayjs from 'dayjs'
import ValidateHelper from 'helper/ValidateHelper'
export const useCardGuestStore = defineStore('cardguest', {
  state: () => ({
    // list
    headers: [
      { text: 'ID', field: 'id', width: '50px'  },
      { text: '表示名', field: 'name', width: '200px'  },
      { text: '開始日', field: 'start_time', width:'170px'  },
      { text: '終了日', field: 'end_time',width:'170px'  },
      { text: 'ステータス', field: 'status', width:'200px'  },
      { text: 'QRコード', field: 'qr_code', width:'100px'  },
      { text: '備考', field: 'note',},
      { text: '操作', field: 'action', width:'75px'}
    ],
    items: [],
    item:{},
    intItem:{id:0, status:1, name:'', note:'', start_time:dayjs(), end_time:''},
    filter:{page:1, limit:20},
    pagination:{total:0, currentPage:1, perPage:1},
    error:{},
  }),
  actions: {
    async getItems() {
      const response = await axios.get(`/api/card-guest?page=${this.filter.page}&limit=${this.filter.limit}`)
      this.items = []
      this.pagination = {total:0, currentPage:1, perPage:1}
      //assign value
      if (response.status == 200 && response.data.total > 0) {
        this.items = response.data.data
        this.pagination.total = response.data.total
        this.pagination.currentPage = response.data.current_page
        this.pagination.perPage = response.data.per_page
      } 
    },

    async save() {
      const response = await axios.post(`/api/card-guest`, this.item)
      if(response.data.code == 200){
        this.getItems()
      }
      return response.data
    },

    async validateSave(){
      this.error = {}
      if (ValidateHelper.isEmpty(this.item.name)) {
        this.error.name = true
      }
      if (ValidateHelper.isEmpty(this.item.start_time)) {
        this.error.start_time = true
      }
      if (!ValidateHelper.isEmpty(this.item.start_time) 
      && !ValidateHelper.isEmpty(this.item.end_time)
      && this.item.start_time > this.item.end_time ) {
        this.error.start_time = true
        this.error.end_time = true
      }
    },
  }
})
