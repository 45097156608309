<script setup>
import { computed, getCurrentInstance } from 'vue'
import { ChevronDoubleLeftIcon, ChevronLeftIcon } from '@heroicons/vue/24/outline';

const $root = getCurrentInstance()?.appContext.config.globalProperties

const props = defineProps({
  total: Number,
  // currentPage: Number,
  perPage: { type: Number, default: 10},
  onPageChange: Function
})

const currentPage = defineModel('currentPage', {
  type: Number,
  default: 1
})

const totalPages = computed(() => Math.ceil(props.total / props.perPage))
const disableFirst = computed(() => currentPage.value == 1)
const disableLast = computed(() => currentPage.value == totalPages.value)

const handlePageChange = async (page) => {
  if (
    (page == 1 && currentPage.value == 1) || 
    (page == totalPages.value && currentPage.value == totalPages.value)
  ) {
    return
  }

  if (page > totalPages.value) {
    page = totalPages.value
  }

  if (page < 1) {
    page = 1
  }

  currentPage.value = page

  $root.overlay.loading = true
  await props.onPageChange()
  $root.overlay.loading = false
}

const handleGoToPage = async (val) => {
  currentPage.value += val
  $root.overlay.loading = true
  await props.onPageChange()
  $root.overlay.loading = false
}
</script>
<template>
  <div class="flex items-center justify-between">
    <div class="text-xs text-gray-400">件数：{{ total }}件</div>
    <div class="flex items-center gap-1" v-if="totalPages > 1">
      <div>
        <button 
          class="p-1.5 rounded hover:opacity-80 group transition duration-300 delay-75 ease-in-out"
          @click.prevent="handlePageChange(1)"
          :disabled="disableFirst"
          :class="{
            'bg-gray-200 text-gray-400': disableFirst,
            'bg-primary text-white': !disableFirst
          }"
        >
          <ChevronDoubleLeftIcon class="w-4 h-4 group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out" />
        </button>
      </div>
      <div>
        <button 
          class="p-1.5 rounded hover:opacity-80 group transition duration-300 delay-75 ease-in-out"
          @click.prevent="handleGoToPage(-1)"
          :disabled="disableFirst"
          :class="{
            'bg-gray-200 text-gray-400': disableFirst,
            'bg-primary text-white': !disableFirst
          }"
        >
          <ChevronLeftIcon class="w-4 h-4 group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out" />
        </button>
      </div>
      <div>
        <button 
          class="p-1.5 rounded hover:opacity-80 group transition duration-300 delay-75 ease-in-out"
          @click.prevent="handleGoToPage(1)"
          :disabled="disableLast"
          :class="{
            'bg-gray-200 text-gray-400': disableLast,
            'bg-primary text-white': !disableLast
          }"
        >
          <ChevronLeftIcon class="w-4 h-4 rotate-180 group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out" />
        </button>
      </div>
      <div>
        <button 
          class="p-1.5 rounded hover:opacity-80 group transition duration-300 delay-75 ease-in-out"
          @click.prevent="handlePageChange(totalPages)"
          :disabled="disableLast"
          :class="{
            'bg-gray-200 text-gray-400': disableLast,
            'bg-primary text-white': !disableLast
          }"
        >
          <ChevronDoubleLeftIcon class="w-4 h-4 rotate-180 group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out" />
        </button>
      </div>
    </div>
  </div>
</template>
