<script setup>
import { ref,  getCurrentInstance } from 'vue'
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogTitle,
  DialogPanel
} from '@headlessui/vue'

const $root = getCurrentInstance()?.appContext.config.globalProperties

import dayjs from 'dayjs'

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import { useCardGuestStore } from 'store/cardguest';
const cardGuestStore = useCardGuestStore()

const isOpen = ref(false)
const open = async () => {
  cardGuestStore.error = {}
  isOpen.value = true
}
const close = () => {
  isOpen.value = false
}

const save = async () => {
  $root.overlay.loading = true

  // validate required fields  
  await cardGuestStore.validateSave()
  if(Object.entries(cardGuestStore.error).length > 0) {
    $root.push.error('必須項目を入力してください')
    $root.overlay.loading = false
    return
  }
  
  cardGuestStore.save().then((result) => {
    if(result.code == 200){
      $root.push.success('保存しました。')
    }
    else{
      $root.push.error('エラーが発生しました')
    }
    close()
  })
  .catch((err) => {
    close()
    $root.push.error('エラーが発生しました')
  });

  $root.overlay.loading = false
}
defineExpose({ open, close })

const format = (date) => {
  return dayjs(date).format('YYYY-MM-DD HH:mm')
}
const handleStartTime = (val) => {
  cardGuestStore.item.start_time = val
}
const handleEndTime = (val) => {
   cardGuestStore.item.end_time = val
}
</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-md transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all rounded-lg p-4"
            >
              <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900 text-center">ゲストカード</DialogTitle>

              <div class="mt-2 flex flex-col gap-2">             
                <div class="flex flex-col">
                  <label>表示名</label>
                  <input v-model="cardGuestStore.item.name" 
                  maxlength="100" type="text" 
                  :class="cardGuestStore.error.name ? 'border-rose-500' : 'border-gray-200' "
                  class="w-full px-2 py-1 border  rounded-md" />
                </div>
                <div class="flex flex-col">
                  <label>備考</label>
                  <input v-model="cardGuestStore.item.note" type="text" maxlength="100" class="w-full px-2 py-1 border border-gray-200 rounded-md" />
                </div>

                <div class="flex flex-row gap-2">
                  <div>
                    <label>開始日</label>
                    <VueDatePicker 
                      v-model="cardGuestStore.item.start_time" 
                      time-picker-inline
                      :format="format"
                      locale="ja"
                      :teleport="true"
                      cancelText="キャンセル"
                      selectText="選択"
                      placeholder="----/--/-- --:--"
                      @update:model-value="handleStartTime"
                      :class="cardGuestStore.error.start_time ? 'border-error' : '' "
                    >
                  </VueDatePicker>
                  </div>
                  <div>
                    終了日
                    <VueDatePicker 
                      v-model="cardGuestStore.item.end_time" 
                      time-picker-inline
                      :format="format"
                      locale="ja"
                      :teleport="true"
                      cancelText="キャンセル"
                      selectText="選択"
                      placeholder="----/--/-- --:--"
                      @update:model-value="handleEndTime"
                      :class="cardGuestStore.error.start_time ? 'border-error' : '' "
                    ></VueDatePicker>
                  </div>                  
                </div>
                <div class="flex flex-col">
                  <label>ステータス</label>
                  <div class="flex flex-row gap-3">
                    <label class="gap-1 flex items-center">
                      <input
                        type="radio"
                        value="1"
                        class="h-4 w-4"
                        v-model="cardGuestStore.item.status"
                      />
                      有効
                    </label>
                    <label class="gap-1 flex items-center">
                      <input
                        type="radio"
                        value="0"
                        class="h-4 w-4"
                        v-model="cardGuestStore.item.status"
                      />
                      無効
                    </label>
                  </div>
                </div>

                <div class="flex items-center gap-4 justify-center pt-4">                  
                  <button
                    type="button" 
                    class="p-2 rounded-md bg-primary text-white hover:opacity-80 min-w-20"
                    @click="save"
                  >
                  {{ cardGuestStore.item.id > 0 ? '更新' : '登録する' }}
                  </button>
                  <button 
                    type="button" 
                    class="p-2 rounded-md bg-gray-100 text-gray-800 hover:opacity-80"
                    @click="close"
                  >
                    キャンセル
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<style>
.border-error input{
  border-color: #f43f5e;
}
</style>