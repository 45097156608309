import { defineStore } from 'pinia'

export const useResource = defineStore('resource', {
  state: () => ({
    item: {
      id: 0,
      name: '',
      location: '',
      manager_name: '',
      phone: '',
      notes: '',
      schedule: {
        mode: ['week', 'custom'][Math.floor(Math.random() * 2)],
        open: '08:00',
        close: '18:00',
        days: Array.from({ length: 7 }, (_, i) => {
          return Math.random() > 0.5 ? i : -1
        })
      }
    },
    items: [],
    expanded: true,
    search: '',
  }),
  actions: {
    async getItems() {
      const response = await axios.get(`/api/resources`);

      if (response.status !== 200) {
        this.errors.response = 'エラーが発生しました。しばらくしてからもう一度お試しください。'
        return
      }
      this.items = response.data;
    },
    async save() {
    }
  },
  getters: {
    searchItems() {
      return this.items.filter((item) => {
        return item.name.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  }
})
