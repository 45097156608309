import { defineStore } from 'pinia'
import DateTimeHelper from 'helper/DatetimeHelper';
export const useEntranceStore = defineStore('entrance', {
  state: () => ({
    // list
    headers: [
      { text: '名前', field: 'name', width: '200px'  },
      { text: 'タイプ', field: 'type', width: '100px'  },
      { text: '電話番号', field: 'phone', width:'200px'  },
      { text: '回数', field: 'count', width:'50px'  },
      { text: '最後入校日時', field: 'checkin_time', width:'200px'  },
      { text: '最後出校日時', field: 'checkout_time',width:'200px'  },
      { text: '最後入出校目的', field: 'reason', }
    ],
    arrType: {
      'club':'クラブ',
      'parent':'保護者',
      'student':'生徒',
      'guest':'ゲスト',
    },
    filter:{text:'', page:1, limit:20},
    pagination:{total:0, currentPage:1, perPage:1},

    items: [],
    item: {},
    
    //detail
    filterDetail: {from:'', to:'',uid:0, page:1, limit:20},
    detailItems: [],
    paginationDetail:{total:0, currentPage:1, perPage:1},
  }),
  actions: {

    async getItems() {
      const response = await axios.post('/api/list-visitor', this.filter)
      //reset value
      this.items = []
      this.pagination = {total:0, currentPage:1, perPage:1}

      //assign value
      if (response.status == 200 && response.data.total > 0) {
        this.items = response.data.data.map(item =>{
          item.checkin_time = DateTimeHelper.format(item.checkin_time, 'YYYY/MM/DD(ddd)　HH:mm')
          item.checkout_time = DateTimeHelper.format(item.checkout_time, 'YYYY/MM/DD(ddd)　HH:mm')
          item.name = this.showName(item)
          item.type_name = this.arrType[item.type]
          item.count = response.data.count[item.id]
          return item
        })
        this.pagination.total = response.data.total
        this.pagination.currentPage = response.data.current_page
        this.pagination.perPage = response.data.per_page
      }      
    },

    showName(val){
      if(val.type == 'student'){
        return val.student_name
      }
      else if(val.type == 'parent'){
        return val.parent_name
      }
      else if(val.type == 'club'){
        return val.club_name
      }
      else{
        return val.guest_name
      }
    },

    async getItemDetail() {
      this.filterDetail.uid = this.item.uid
      this.filterDetail.type = this.item.type
      if(this.item.type == 'guest'){
        this.filterDetail.phone = this.item.guest_phone
      }
      const response = await axios.post('/api/visitor-detail', this.filterDetail)
      this.detailItems = []
      this.paginationDetail = {total:0, currentPage:1, perPage:1}
      if (response.status == 200 && response.data.total > 0) {
        this.detailItems = response.data.data.map(item =>{
          item.checkin_time = DateTimeHelper.format(item.checkin_time, 'YYYY/MM/DD(ddd)　HH:mm')
          item.checkout_time = DateTimeHelper.format(item.checkout_time, 'YYYY/MM/DD(ddd)　HH:mm')
          return item
        })
        this.paginationDetail.total = response.data.total
        this.paginationDetail.currentPage = response.data.current_page
        this.paginationDetail.perPage = response.data.per_page
      }
    }
  }
})
