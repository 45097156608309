import { defineStore } from 'pinia'
import APPJSH from '../helper/ConstantHelper'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    // auth form
    form: {
      loginId: '',
      password: '',
    },
    errors: {
      loginId: '',
      password: '',
      response: '',
    },

    // cache
    user: null,
  }),
  getters: {
    isAuthenticated: (state) => {
      // get data from local storage
      const user = localStorage.getItem('user')

      if (user) {
        state.user = JSON.parse(user)
      }

      return !!state.user && !!state.user.token
    },
    username: (state) => {
      return state.user ? state.user.name : ''
    }
  },
  actions: {
    initErrors() {
      this.errors.loginId = ''
      this.errors.password = ''
      this.errors.response = ''
    },
    async login() {
      this.initErrors()

      if (!this.form.loginId) {
        this.errors.loginId = 'ログインIDを入力してください'
        return
      }

      if (!this.form.password) {
        this.errors.password = 'パスワードを入力してください'
        return
      }

      const data = { login_id: this.form.loginId, password: this.form.password }
      try {
        const response = await axios.post('/api/login', data)
        if (response.status !== 200) {
          this.errors.response = 'ログインIDまたはパスワードが間違っています'
          return
        }
        
        if (response.data.data) {
          this.user = response.data.data

          // save data to local storage
          localStorage.setItem('user', JSON.stringify(this.user))

          this.form.loginId = ''
          this.form.password = ''
        }

        this.errors.response = ''
      } catch (error) {
        this.errors.response = 'ログインに失敗しました'
      }
    },
    async logout() {
      try {
        await axios.post('/api/logout')

        // remove data from local storage
        localStorage.removeItem('user')
        this.user = null
      } catch (error) {
        console.error(error)

        localStorage.removeItem('user')
        this.user = null
      }
    },
  },
})
