<script setup>
import { onMounted, getCurrentInstance } from 'vue'
import { useEmailHistoryStore } from '../../js/store/email-history'
import TableEmailHistory from '../components/TableEmailHistory.vue';

const $root = getCurrentInstance()?.appContext.config.globalProperties
const emailHistoryStore = useEmailHistoryStore()

onMounted(async () => {
  $root.overlay.loading = true
  await emailHistoryStore.getItems()
  $root.overlay.loading = false
})
</script>
<template>
  <TableEmailHistory />
</template>
