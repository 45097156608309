<script setup>
import { ref, getCurrentInstance } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from '@headlessui/vue'
import { useEmailSendStore } from '../../js/store/email-send';
import APPJSH from '../../js/helper/ConstantHelper';

const $root = getCurrentInstance()?.appContext.config.globalProperties
const emailSendStore = useEmailSendStore()

const isOpen = ref(false)
const open = async () => {
  isOpen.value = true
}
const close = () => {
  isOpen.value = false
}

const send = async () => {
  // check time with now
  if (emailSendStore.item.type === APPJSH.EMAIL_SEND_TYPES.RESERVE) {
    const now = new Date()
    const sendTime = new Date(emailSendStore.item.date)
    if (sendTime < now) {
      $root.push.error('配信日時は現在時刻より後の日時を指定してください')
      return
    }
  }

  $root.push.info('送信中...')
  emailSendStore.send().then(() => {
    emailSendStore.resetItem()
  }).catch(() => {
    $root.push.error('送信に失敗しました')
  })

  close()
}

defineExpose({ open, close })
</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-3xl transform overflow-hidden bg-graysp text-left align-middle shadow-xl transition-all p-4 border-t-2 border-t-primary"
            >
              <div class="flex flex-col gap-2">
                <div class="flex items-start gap-6">
                  <div class="flex-none w-32 font-semibold">
                    配信対象
                  </div>
                  <div class="grow">
                    <div class="flex items-center gap-2">
                      <div 
                        v-for="target in emailSendStore.item.targets" 
                        class="rounded border border-primary px-2 py-px group bg-primary/10"
                      >
                        {{ target.value }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex items-center gap-6">
                  <div class="flex-none w-32 font-semibold">
                    配信日時設定
                  </div>
                  <div class="grow">
                    {{ emailSendStore.getSendTypeText }}
                  </div>
                </div>

                <div class="flex items-center gap-6">
                  <div class="flex-none w-32 font-semibold">
                    添付ファイル
                  </div>
                  <div class="flex items-center gap-1 text-pblue">
                    <div v-if="emailSendStore.item.attachments" class="flex flex-col">
                      <div v-for="attachment in emailSendStore.item.attachments">
                        {{ attachment.name }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex items-center gap-6">
                  <div class="flex-none w-32 font-semibold">
                    件名
                  </div>
                  <div class="grow">
                    <div class="flex w-full px-2 py-1 border bg-white rounded">
                      {{ emailSendStore.item.subject }}
                    </div>
                  </div>
                </div>

                <div class="grid grid-cols-2 gap-4">
                  <div class="flex flex-col gap-1">
                    <div class="flex items-center">
                      <div class="font-semibold">本文</div>
                      <div class="text-xs">（テキストメール）</div>
                    </div>
                    <div class="grow">
                      <div class="px-2 py-1 border bg-white rounded h-full max-h-96 overflow-auto">
                        <div v-html="emailSendStore.parseTextToHtml"></div>
                      </div>
                    </div>
                  </div>
                
                  <div class="flex flex-col gap-1">
                    <div class="flex items-center">
                      <div class="font-semibold">本文</div>
                      <div class="text-xs">（HTMLメール）</div>
                    </div>
                    <div class="grow">
                      <div class="px-2 py-1 border bg-white rounded h-full max-h-96 overflow-auto">
                        <div v-html="emailSendStore.item.content_html"></div>
                      </div>
                    </div>
                    
                  </div>
                </div>

                <div class="flex items-center justify-end gap-4 font-semibold">
                  <div>
                    <button 
                      type="button"
                      class="px-4 py-1 bg-primary text-white rounded hover:opacity-80"
                      @click.prevent="send"
                    >
                      送信する
                    </button>
                  </div>

                  <div>
                    <button 
                      type="button"
                      class="px-4 py-1 bg-white text-gray-500 rounded border hover:opacity-80"
                      @click="close"
                    >
                      キャンセル
                    </button>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
