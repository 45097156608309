import { defineStore } from 'pinia'

export const useRoom = defineStore('room', {
  state: () => ({
    item: {
      id: 0,
      name: '',
      location: '',
      description: '',
      manager_name: '',
      phone: '',
      note: '',
      schedule: {
        open: '08:00',
        close: '18:00',
        mode: 'week',
        days: [],
      }
    },
    items: [],
    expanded: true,
    search: '',
  }),
  actions: {
    async getItems() {
      const response = await axios.get(`/api/classrooms`);
      if (response.status !== 200) {
        this.errors.response = 'エラーが発生しました。しばらくしてからもう一度お試しください。'
        return
      }
      this.items = response.data;
    }
  },
  getters: {
    searchItems() {
      return this.items.filter((item) => {
        return item.name.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  }
})
