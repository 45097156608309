<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue'
const $root = getCurrentInstance()?.appContext.config.globalProperties

import { PencilIcon } from '@heroicons/vue/24/outline';

import DateTimeHelper from 'helper/DatetimeHelper';

import { useCardGuestStore } from 'store/cardguest';
const cardGuestStore = useCardGuestStore()

import CardItemModel from './CardItemModel.vue';

import QrItemModal from 'views/components/QrItemModal.vue';
const refQrModal = ref(null)

import TablePagination from 'views/components/TablePagination.vue';

onMounted(async () => {
  fetchData()
})

const fetchData = async() =>{
  $root.overlay.loading = true
  await cardGuestStore.getItems()
  $root.overlay.loading = false
}
const refCardItemModel = ref(null)
const showModel = (item) =>{
  if(item.id > 0){
    cardGuestStore.item = JSON.parse(JSON.stringify(item))
  }
  else{
    cardGuestStore.item = JSON.parse(JSON.stringify(cardGuestStore.intItem))
  }
  refCardItemModel.value.open()
}
</script>
<template>
  <div class="w-full bg-white rounded-md p-4 flex flex-col gap-2">
    <div class="flex justify-start">
      <button class="px-4 py-1 bg-primary shadow text-white rounded hover:opacity-80"
        @click.prevent="showModel({})"
      >
        新規登録 
      </button>
    </div>

    <table class="w-full table">
      <thead class="border-t-2 border-t-secondary border-b border-b-gray-200 bg-gray-100 text-xs">
        <tr>
          <th v-for="header in cardGuestStore.headers" class="font-normal p-2" 
          :class="header.field == 'count' ? 'text-center' : 'text-left'"
          :style="`width:${header.width};`"
          >
            {{ header.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="cardGuestStore.items.length">
          <tr v-for="(item, index) in cardGuestStore.items" class="border-b border-b-gray-200">
            <td class="p-2">{{ item.id }}</td>
            <td class="p-2">
              <div class="line-clamp-1">
                {{ item.name }}
              </div>
            </td>            
            <td class="p-2">{{ DateTimeHelper.format(item.start_time, 'YYYY/MM/DD(ddd)　HH:mm') }}</td>
            <td class="p-2">{{ DateTimeHelper.format(item.end_time, 'YYYY/MM/DD(ddd)　HH:mm') }}</td>
            <td class="p-2">{{ item.status == 1 ? '有効' : '無効' }}</td>            
            <td class="p-2">
              <img v-if="item.qr_code_url" @click.prevent="refQrModal.open(item.qr_code_url)" :src="item.qr_code_url" class="w-12 h-12" loading="lazy" />
            </td>
            <td class="p-2">
              <div class="line-clamp-1">
                {{ item.note}}
              </div>
            </td>
            <td class="p-2">
              <div class="flex gap-2 items-center">
                <button 
                  type="button" 
                  class="p-1.5 rounded-full bg-green group hover:opacity-80"
                  @click.prevent="showModel(item)"
                >
                  <PencilIcon class="w-4 h-4 text-white group-hover:scale-110 transition-all delay-75 duration-300 ease-in-out" />
                </button>
              </div>
            </td>
          </tr>
        </template>
        <tr v-else-if="!$root.overlay.loading">
          <td class="p-2 text-center text-xs text-grey" :colspan="6">
            データがありません
          </td>
        </tr>
      </tbody>
    </table>

    <TablePagination 
    :onPageChange="fetchData"
    :total="cardGuestStore.pagination.total" 
    v-model:currentPage="cardGuestStore.filter.page"
    :perPage="cardGuestStore.pagination.perPage" />

  </div>
  <CardItemModel ref="refCardItemModel" />
  <QrItemModal ref="refQrModal" />
</template>
