<script setup>
import { ref} from 'vue'
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel
} from '@headlessui/vue'
const url = ref(null)
const isOpen = ref(false)
const open = async (qr_url) => {

  if(qr_url) {
    url.value = qr_url;
  }
  isOpen.value = true
}
const close = () => {
  isOpen.value = false
}
defineExpose({ open, close })
</script>
<template>
    <TransitionRoot appear :show="isOpen" as="template" @close="isOpen = false">
        <Dialog as="div" class="relative z-10">
        <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
        >
            <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
            <div
            class="flex min-h-full items-center justify-center p-4 text-center"
            >
            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
            >
                <DialogPanel
                class="max-w-md transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all rounded-lg p-4"
                >
                <div>
                    <img :src="url" class="w-60 h-60" loading="lazy" />
                </div>
                </DialogPanel>
            </TransitionChild>
            </div>
        </div>
        </Dialog>
  </TransitionRoot>
</template>