<script setup>
import { ref } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from '@headlessui/vue'
import { useEmailHistoryStore } from '../../js/store/email-history';
import APPJSH from '../../js/helper/ConstantHelper'

const emailHistoryStore = useEmailHistoryStore()
const item = ref({})

const isOpen = ref(false)
const open = async (id) => {
  if (!id) {
    return
  }

  item.value = emailHistoryStore.getItem(id)

  console.log(item.value)

  isOpen.value = true
}
const close = () => {
  isOpen.value = false
}

defineExpose({ open, close })
</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-3xl transform overflow-hidden bg-graysp text-left align-middle shadow-xl transition-all p-4 border-t-2 border-t-primary"
            >
            <div class="flex flex-col gap-2">
                <div class="flex items-center gap-6">
                  <div class="flex-none w-32 font-semibold">
                    配信対象
                  </div>
                  <div class="grow">
                    <div class="flex items-center gap-1 flex-wrap">
                      <div v-for="target in item.targets" class="rounded border border-primary px-2 bg-primary/10">
                        {{ target === 'all' ? '全学年' : target.replace(/-/g, ' ・ ') }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex items-center gap-6">
                  <div class="flex-none w-32 font-semibold">
                    配信日時設定
                  </div>
                  <div class="grow">
                    {{ emailHistoryStore.getSendTypeText(item) }}
                  </div>
                </div>

                <div class="flex items-center gap-6">
                  <div class="flex-none w-32 font-semibold">
                    添付ファイル
                  </div>
                  <div class="flex items-center gap-1">
                    <div v-if="item.attachments" class="flex flex-col">
                      <a 
                        v-for="attachment in item.attachments"
                        :href="attachment.path" 
                        :title="item.name" 
                        download
                      >
                        <span class="underline text-pblue">{{ attachment.name }}</span> ({{ attachment.size }})
                      </a>
                    </div>
                  </div>
                </div>

                <div class="flex items-center gap-6">
                  <div class="flex-none w-32 font-semibold">
                    件名
                  </div>
                  <div class="grow">
                    <div class="flex w-full px-2 py-1 border bg-white rounded">
                      {{ item.subject }}
                    </div>
                  </div>
                </div>

                <div class="grid grid-cols-2 gap-4">
                  <div class="flex flex-col gap-1">
                    <div class="flex items-center">
                      <div class="font-semibold">本文</div>
                      <div class="text-xs">（テキストメール）</div>
                    </div>
                    <div class="grow">
                      <div class="px-2 py-1 border bg-white rounded h-full max-h-96 overflow-auto">
                        <div v-html="emailHistoryStore.parseTextToHtml(item.content_text)"></div>
                      </div>
                    </div>
                  </div>
                
                  <div class="flex flex-col gap-1">
                    <div class="flex items-center">
                      <div class="font-semibold">本文</div>
                      <div class="text-xs">（HTMLメール）</div>
                    </div>
                    <div class="grow">
                      <div class="px-2 py-1 border bg-white rounded h-full max-h-96 overflow-auto">
                        <div v-html="item.content_html"></div>
                      </div>
                    </div>
                    
                  </div>
                </div>

                <div class="flex items-center justify-between gap-4 font-semibold">
                  <div>
                    <div 
                      class="py-0.5 px-2 text-white text-xs font-bold w-fit rounded-full"
                      :class="{
                        'bg-tertiary': item.status === 'pending',
                        'bg-error': item.status === 'error',
                        'bg-green': item.status === 'success',
                      }"
                    >
                      {{ APPJSH.EMAIL_STATUS[item.status] }}
                    </div>
                  </div>
                  <div>
                    <button 
                      type="button"
                      class="px-4 py-1 bg-white text-gray-500 rounded border hover:opacity-80"
                      @click="close"
                    >
                      閉じる
                    </button>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
