<script setup>
import { ref, getCurrentInstance } from 'vue'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { PlusIcon, ArrowPathIcon, TrashIcon, QrCodeIcon, PencilIcon, MagnifyingGlassIcon, ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
import ClubItemSearchMember from './ClubItemSearchMember.vue';
import { useClubStore } from '../../js/store/club';
import TablePagination from './TablePagination.vue';
import ClubMemberGuestModal from './ClubMemberGuestModal.vue';
import ClubMemberModal from './ClubMemberModal.vue';
import DateTimeHelper from '../../js/helper/DatetimeHelper';

const $root = getCurrentInstance()?.appContext.config.globalProperties
const clubStore = useClubStore()

const removeMember = async (id) => {
  const c = confirm('本当に削除しますか？')
  if (!c) {
    return
  }

  $root.overlay.loading = true
  try {
    await clubStore.removeMember(id)
    $root.push.success('メンバーを削除しました')
  } catch (error) {
    $root.push.error('メンバーの削除に失敗しました')
  }
  $root.overlay.loading = false
}

const clubItemSearchMember = ref(null)
const clubMemberGuestModal = ref(null)
const clubMemberModal = ref(null)

const genQrcodes = () => {
  const c = confirm('QRコードを生成しますか？\r\nまだQRコードを持っていないメンバーのみQRコードを生成します。')
  if (!c) {
    return
  }

  try {
    clubStore.genQrcodes()
    $root.push.success('QRコードを生成しました')
  } catch (error) {
    $root.push.error('QRコードの生成に失敗しました')
  }
}

const refresh = async () => {
  if (!clubStore.item || !clubStore.item.id) {
    return
  }

  $root.overlay.loading = true
  await clubStore.getMembers()
  $root.overlay.loading = false
}

const doSearchMembers = async () => {
  clubStore.currentPageMembers = 1

  $root.overlay.loading = true
  await clubStore.getMembers()
  $root.overlay.loading = false
}

const editMember = async (id, type) => {
  if (!type) {
    return
  }

  if (type === 'guest') {
    clubMemberGuestModal.value.open(id, type)
    return
  }
  
  clubMemberModal.value.open(id, type)
}
</script>
<template>
  <div class="flex flex-col gap-1 w-full">
    <div class="flex items-center justify-between w-full">
      <!-- total -->
      <!-- <div class="text-xs text-gray-400">件数：{{ clubStore.item.members.length }}件</div> -->
      <div class="flex items-center gap-1">
        <div class="w-64">
          <!-- search by name, phone, student code -->
          <input 
            type="text" 
            class="w-full p-1 border border-gray-200 rounded" 
            placeholder="名前、電話番号、学籍番号で検索"
            v-model="clubStore.searchMemberText"
            @keyup.enter="doSearchMembers"
          />
        </div>  
        <button 
          type="button"
          class="p-1.5 rounded hover:opacity-80 group transition duration-300 delay-75 ease-in-out bg-white-300 border"
          title="検索"
          @click.prevent="doSearchMembers"
        >
          <MagnifyingGlassIcon class="w-4 h-4 group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out" />
        </button>
      </div>
      
      <!-- add btn -->
      <div class="flex items-center gap-1">
        <button 
          type="button"
          class="p-1.5 rounded hover:opacity-80 group transition duration-300 delay-75 ease-in-out bg-primary text-white"
          title="メンバーを追加する"
          @click="clubItemSearchMember.open"
        >
          <PlusIcon class="w-4 h-4 group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out" />
        </button>
        <button 
          type="button"
          class="p-1.5 rounded hover:opacity-80 group transition duration-300 delay-75 ease-in-out bg-white-300 border"
          title="QRコードを生成する"
          @click.prevent="genQrcodes"
        >
          <QrCodeIcon class="w-4 h-4 group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out" />
        </button>
        <!-- refresh button -->
        <button 
          type="button"
          class="p-1.5 rounded hover:opacity-80 group transition duration-300 delay-75 ease-in-out bg-white-300 border"
          @click.prevent="refresh"
          title="更新する"
          >
          <ArrowPathIcon class="w-4 h-4 group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out" />
        </button>
      </div>
    </div>
    <div class="flex flex-col gap-2">
      <table class="w-full table">
        <thead class="border-t-2 border-t-secondary border-b border-b-gray-200 bg-gray-100 text-xs">
          <tr>
            <th>名前</th>
            <th>電話番号</th>
            <th>タイプ</th>
            <th class="text-center">QRコード</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <template  v-if="clubStore.item.members && clubStore.item.members.length">
            <tr v-for="member in clubStore.item.members" class="border-b border-b-gray-200">
              <td>
                <span v-html="member.name"></span>
              </td>
              <td>{{ member.phone }}</td>
              <td>
                {{ 
                  member.member_type === 'guest' ? 'ゲスト'
                    : (member.member_type === 'student' ? '学生' : '保護者') 
                }}
              </td>
              <td class="text-center">
                <div class="flex items-center justify-center gap-1">
                  <Popover v-if="member.qr_code_image"  v-slot="{ open }" class="relative">
                    <PopoverButton
                      title="QRコードを表示" 
                      class="cursor-pointer group"
                    >
                      <QrCodeIcon class="w-5 h-5 p-px border rounded group-hover:scale-110 transition duration-300 delay-75 ease-in-out" />
                    </PopoverButton>
                    <transition
                      enter-active-class="transition duration-200 ease-out"
                      enter-from-class="translate-y-1 opacity-0"
                      enter-to-class="translate-y-0 opacity-100"
                      leave-active-class="transition duration-150 ease-in"
                      leave-from-class="translate-y-0 opacity-100"
                      leave-to-class="translate-y-1 opacity-0"
                    >
                      <PopoverPanel v-slot="{ close }"
                        class="absolute z-10 transform px-4 -top-20 -left-1/2 -translate-x-1/3"
                      >
                        <div class="flex flex-col gap-2 w-[300px] p-2 rounded-lg bg-white shadow-2xl">
                          <img :src="member.qr_code_image" alt="QRコード" loading="lazy" />

                          <div class="flex items-center gap-2 justify-center">
                            <!-- download button -->
                            <a 
                              :href="member.qr_code_image" 
                              download="qr_code.png"
                              class="p-1 rounded hover:opacity-80 group transition duration-300 delay-75 ease-in-out bg-primary text-white"
                            >
                              ダウンロード
                            </a>

                            <!-- close button -->
                            <button 
                              class="p-1 rounded hover:opacity-80 group transition duration-300 delay-75 ease-in-out border"
                              @click.prevent="close"
                            >
                              閉じる
                            </button>
                          </div>
                        </div>
                      </PopoverPanel>
                    </transition>
                  </Popover>
                  <a href="#" title="有効期限が切れました"
                    v-if="DateTimeHelper.isBeforeToday(member.expired_at)" 
                  >
                    <ExclamationTriangleIcon class="2-4 h-4 text-error" />
                  </a>
                </div>
              </td>
              <td>
                <div class="flex items-center justify-end gap-2 w-full">
                  <PencilIcon 
                    class="w-4 h-4 text-primary group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out cursor-pointer" 
                    @click.prevent="editMember(member.id, member.member_type)"
                  />
                  <TrashIcon 
                    class="w-4 h-4 text-error group-hover:scale-110 transition-all duration-300 delay-75 ease-in-out cursor-pointer" 
                    @click.prevent="removeMember(member.id)"
                  />
                </div>
              </td>
            </tr>
          </template>
          <tr v-else>
            <td colspan="5" class="text-center text-xxs text-gray-200">データがありません</td>
          </tr>
        </tbody>
      </table>

      <TablePagination 
        v-model:currentPage="clubStore.currentPageMembers"
        :total="clubStore.totalMembers" 
        :perPage="clubStore.perpageMembers" 
        :onPageChange="clubStore.getMembers"
      />

      <!-- help for ExclamationTriangleIcon -->
      <div class="flex items-center gap-1">
        <ExclamationTriangleIcon class="w-4 h-4 text-error" />
        <span class="text-xs text-gray-400">有効期限が切れたメンバーはQRコードを表示できません</span>
      </div>
    </div>
  </div>

  <ClubItemSearchMember ref="clubItemSearchMember" />
  <ClubMemberGuestModal ref="clubMemberGuestModal" />
  <ClubMemberModal ref="clubMemberModal" />
</template>
