import { defineStore } from 'pinia'

export const useImportStore = defineStore('import', {
  state: () => ({
    headers: [
      {
        text: 'ID',
        value: 'id',
      },
      {
        text: 'ファイル名',
        value: 'file_name',
      },
      {
        text: 'インポート日時',
        value: 'created_at',
      },
      {
        text: 'インポート者',
        value: 'imported_by',
      },
      {
        text: 'ステータス',
        value: 'status',
      },
      {
        text: '備考',
        value: 'note'
      },
    ],
    items: [],
    item: {},
    total: 0,
    currentPage: 1,
    perPage: 20
  }),
  actions: {
    async getItems() {
      const response = await axios.get('/api/importLogs?per_page=' + this.perPage + '&page=' + this.currentPage)
      if (response.status !== 200) {
        this.errors.response = 'ログインIDまたはパスワードが間違っています'
        return
      }
      this.items = response.data.data;
      this.total = response.data.total;
      this.currentPage = response.data.current_page;
    }
  }
})
