const FormatHelper = {
  formatDateTime: (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
  
    const hour = date.getHours();
    const minute = date.getMinutes();
  
    // add zero in front of numbers < 10
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedHour = hour < 10 ? `0${hour}` : hour;
    const formattedMinute = minute < 10 ? `0${minute}` : minute;
  
    return `${year}-${formattedMonth}-${formattedDay} ${formattedHour}:${formattedMinute}`;
  },
  formatDate: (date) => {
    if (!date) return ''

    if (typeof date === 'string') {
      date = new Date(date)
    }

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
  
    // add zero in front of numbers < 10
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
  
    return `${year}-${formattedMonth}-${formattedDay}`;
  },
  formatTime: (date) => {
    const hour = date.hours
    const minute = date.minutes
  
    // add zero in front of numbers < 10
    const formattedHour = hour < 10 ? `0${hour}` : hour;
    const formattedMinute = minute < 10 ? `0${minute}` : minute;
  
    return `${formattedHour}:${formattedMinute}:00`;
  },
  convertToTimeObject: (time) => {
    const [hours, minutes] = time.split(':')
    return {
      hours: parseInt(hours),
      minutes: parseInt(minutes)
    }
  }
}

export default FormatHelper;
