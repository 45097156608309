<script setup>
import { onMounted, getCurrentInstance } from 'vue'
import { useEmailTemplate } from '../../../js/store/template'
import { Switch } from '@headlessui/vue'
import { ChevronDoubleRightIcon } from '@heroicons/vue/24/outline'
import EditorContent from '../../components/EditorContent.vue'
import APPJSH from '../../../js/helper/ConstantHelper'
import { DocumentDuplicateIcon } from '@heroicons/vue/24/outline';

const $root = getCurrentInstance()?.appContext.config.globalProperties
const templateStore = useEmailTemplate()

onMounted(async () => {
   $root.overlay.loading = true
   await templateStore.getItems()
   $root.overlay.loading = false
})

const handleClick = (item) => {
   if (!item.type) {
      item.type = APPJSH.EMAIL_TEMPLATE_TYPES.HTML
   }
   templateStore.setItem(item)
}

const handleSwitch = (item) => {
   templateStore.saveItemActive(item)
}

const addTemplate = async () => {
   if (!templateStore.item.name.trim()) {
      $root.push.error('設定名を入力してください')
      return
   }

   $root.overlay.loading = true
   await templateStore.addItem()
   $root.overlay.loading = false

   $root.push.success('新規テンプレートを追加しました。')

   handleClick(templateStore.item)
}

const handleSave = async () => {
   if (!templateStore.selected.id) {
      $root.push.error('テンプレートを選択してください')
      return
   }

   if (!templateStore.selected.name.trim()) {
      $root.push.error('設定名を入力してください')
      return
   }

   if (templateStore.selected.content_text.trim() === '') {
      $root.push.error('テキストメールを入力してください')
      return
   }

   if (templateStore.selected.content_html.trim() === '') {
      $root.push.error('HTMLメールを入力してください')
      return
   }

   $root.overlay.loading = true
   await templateStore.saveItem()
   $root.overlay.loading = false

   $root.push.success('設定を保存しました')
}

const handleCopy = () => {
  navigator.clipboard.writeText(templateStore.selected.content_text)
}

const handlePaste = (e) => {
  navigator.clipboard.readText().then(text => {
   emplateStore.selected.content_text = text
  })
}
</script>
<template>
   <div class="w-full bg-white rounded-md p-4 flex justify-center gap-4">
    <div class="flex flex-col border-t-2 border-t-secondary w-1/3">
      <h3 class="px-2 py-1 bg-gray-100 border-b">設定名</h3>
      <div class="flex px-2 py-1 items-center gap-2">
         <div class="grow">
            <input 
               v-model="templateStore.item.name" 
               type="text" 
               class="w-full px-2 py-0.5 rounded border bg-yellow" 
            />
         </div>
         <div class="flex-none">
            <button 
               type="button"
               class="px-4 py-1 bg-primary text-white rounded-md font-semibold"
               @click.prevent="addTemplate"
            >
               新規登録
            </button>
         </div>
      </div>
      <hr />
      <div class="flex flex-col border-t-2 border-t-secondary mt-2">
         <table>
            <thead class="bg-gray-100 border-b">
               <tr>
                  <th 
                     v-for="header in templateStore.headers"
                     class="px-2 py-px bg-gray-100 font-normal text-left"
                     :class="header.class"
                  >
                     {{ header.text }}
                  </th>
               </tr>
            </thead>
            <tbody class="divide-y">
               <template v-if="templateStore.items.length">
                  <tr 
                     v-for="item in templateStore.items" 
                     class="cursor-pointer transition-all delay-75 duration-300 ease-in-out"
                     @click="handleClick(item)"
                     :class="{
                        'bg-primary/5': item.active && item.id === templateStore.selected.id
                     }"
                  >
                     <td class="px-2 py-1">
                        <div class="flex items-center">
                           <Switch 
                              class="relative inline-flex h-6 w-11 items-center rounded-full"
                              :class="{
                                 'bg-primary': item.active,
                                 'bg-gray-200': !item.active
                              }"
                              @update:modelValue="handleSwitch(item)"
                           >
                              <span
                                 class="inline-block h-4 w-4 transform rounded-full bg-white transition"
                                 :class="{
                                    'translate-x-6': item.active,
                                    'translate-x-1': !item.active
                                 }"
                              />
                           </Switch>
                        </div>
                     </td>
                     <td 
                        class="px-2 py-1"
                        :class="{
                           'text-gray-400': !item.active,
                           'text-primary': item.active && item.id === templateStore.selected.id
                        }"
                     >
                        <div class="flex items-center justify-between">
                           {{ item.name }}
                           <ChevronDoubleRightIcon v-if="item.id === templateStore.selected.id" class="h-5 w-5" />
                        </div>
                     </td>
                  </tr>
               </template>
               <tr v-else-if="!$root.overlay.loading">
                  <td 
                     class="px-2 py-1 text-center text-xs text-grey" 
                     :colspan="templateStore.headers.length"
                  >
                     データがありません
                  </td>
               </tr>
            </tbody>
         </table>
      </div>
    </div>
    <div class="flex flex-col border border-t-2 border-t-secondary w-2/3">
      <h3 class="px-2 py-1 bg-gray-100 border-b flex justify-between items-center font-semibold">
         テンプレート
         <button 
            type="button"
            class="px-4 py-px bg-tertiary text-white rounded-md"
            @click.prevent="handleSave"
         >
            設定を保存
         </button>
      </h3>
      <!-- name -->
      <div class="flex items-center gap-4 px-2 py-1">
         <div class="flex-none w-fit">
            設定名
         </div>
         <div class="grow">
            <input 
               v-model="templateStore.selected.name" 
               type="text" 
               class="w-full px-2 py-0.5 rounded border bg-yellow max-w-lg" 
            />
         </div>
      </div>
      <hr />
      <div class="flex flex-col h-full">
         <!-- text or html format -->
         <div class="flex items-center justify-between">
            <div class="flex items-center text-xs">
               <button 
                  type="button" 
                  class="px-4 py-1 transition-all delay-75 duration-300 ease-in-out"
                  :class="{
                     'bg-primary/30 border-b-0': templateStore.selected.type === APPJSH.EMAIL_TEMPLATE_TYPES.TEXT,
                     'hover:bg-primary/10': templateStore.selected.type !== APPJSH.EMAIL_TEMPLATE_TYPES.TEXT,
                  }"
                  @click.prevent="templateStore.selected.type = APPJSH.EMAIL_TEMPLATE_TYPES.TEXT"
               >
                  テキスト
               </button>
               <button 
                  type="button" 
                  class="px-4 py-1 transition-all delay-75 duration-300 ease-in-out"
                  :class="{
                     'bg-primary/30 border-b-0': templateStore.selected.type === APPJSH.EMAIL_TEMPLATE_TYPES.HTML,
                     'hover:bg-primary/10': templateStore.selected.type !== APPJSH.EMAIL_TEMPLATE_TYPES.HTML,
                  }"
                  @click.prevent="templateStore.selected.type = APPJSH.EMAIL_TEMPLATE_TYPES.HTML"
               >
                  HTML
               </button>
               <span class="text-error text-xxs ml-4">※テキストメールも必ず設定して下さい。</span>
            </div>
         </div>
         
         <div class="transition-all delay-75 duration-300 ease-in-out h-full">
            <div 
               class="flex w-full h-full group relative"
               v-show="templateStore.selected.type === APPJSH.EMAIL_TEMPLATE_TYPES.TEXT"
            >
               <DocumentDuplicateIcon 
                  class="absolute top-2 right-2 h-6 w-6 text-gray-400 cursor-pointer hidden group-hover:flex z-10 hover:text-slate-900" 
                  @click.prevent="handleCopy"
               />
               <textarea 
                  v-model="templateStore.selected.content_text" 
                  class="flex h-full w-full rounded-b-md p-2 bg-yellow" 
                  @paste="handlePaste"
               />
            </div>
            <EditorContent 
               v-show="templateStore.selected.type === APPJSH.EMAIL_TEMPLATE_TYPES.HTML"
               v-model:content="templateStore.selected.content_html" 
               class="flex flex-col h-full bg-yellow" 
            />
         </div>
      </div>
    </div>
   </div>
</template>
