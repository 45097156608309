import { defineStore } from 'pinia'
import APPJSH from '../helper/ConstantHelper'

export const useEmailTemplate = defineStore('email-template', {
  state: () => ({
    headers : [
      {
        text: '有効/無効',
        value: 'active',
        class: 'w-20'
      },
      {
        text: '設定名',
        value: 'name',
        class: ''
      },
    ],
    items: [],
    item: {
      name: '',
      content_text: '',
      content_html: '',
      active: true,
    },
    selected: {
      id: 0,
      name: '',
      content_text: '',
      content_html: '',
      active: true,
      type: APPJSH.EMAIL_TEMPLATE_TYPES.HTML,
    }
  }),
  actions: {
    async getItems() {
      const response = await axios.get('/api/email-template')

      if (response.status === 200) {
        this.items = response.data.data
      }
    },
    async updateItem() {
      console.log(this.item)
    },
    async addItem() {
      const response = await axios.post('/api/email-template', this.item)

      if (response.status === 200) {
        this.item = response.data.data

        // insert to items in the first index
        this.items.unshift(response.data.data)

        setTimeout(() => {
          this.selected = response.data.data
          this.selected.type = 'html'
        }, 1000)
      }

      this.item = {
        name: '',
        content_text: '',
        content_html: '',
        active: true,
      }
    },
    setItem(item) {
      this.selected = Object.assign({}, item)

      if (!this.selected.content_text) {
        this.selected.content_text = ' ';
      }
      if (!this.selected.content_html) {
        this.selected.content_html = ' ';
      }
    },
    async saveItem() {
      if (
        !this.selected || 
        !this.selected.id || 
        (this.selected.content_text.trim() === '' && this.selected.content_html.trim() === '')
      ) {
        return
      }

      try {
        const response = await axios.put(`/api/email-template/${this.selected.id}`, {
          name: this.selected.name,
          content_text: this.selected.content_text.trim(),
          content_html: this.selected.content_html.trim(),
        })
  
        if (response.status === 200) {
          this.selected = response.data.data
  
          // update items
          const index = this.items.findIndex(item => item.id === this.selected.id)
          this.items[index] = this.selected
  
          this.selected.type = APPJSH.EMAIL_TEMPLATE_TYPES.HTML
        }
      } catch (error) {
        console.error('エラーが発生しました')
      }
    },
    saveItemActive(item) {
      this.selected = Object.assign({}, item)

      if (
        !this.selected || 
        !this.selected.id
      ) {
        return
      }

      try {
        axios.put(`/api/email-template/${this.selected.id}`, {
          active: !this.selected.active,
        }).then(response => {
          if (response.status === 200) {
            this.selected.active = response.data.data.active
  
            // update items
            const index = this.items.findIndex(item => item.id === this.selected.id)
            this.items[index] = this.selected
          }
        })
      } catch (error) {
        console.error('エラーが発生しました')
      }
    }
  }
})
