<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue'
import TableAccount from '../components/TableAccount.vue';
import { useAccountStore } from '../../js/store/account';

const accountStore = useAccountStore()
const $root = getCurrentInstance()?.appContext.config.globalProperties

onMounted(async () => {
  $root.overlay.loading = true
  await accountStore.getItems()
  $root.overlay.loading = false
})
</script>
<template>
  <TableAccount />
</template>
