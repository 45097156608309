<script setup>
import { ref, getCurrentInstance } from 'vue'
import { ChevronLeftIcon, ChevronRightIcon, PlusIcon } from '@heroicons/vue/24/outline';
import { useClubStore } from '../../js/store/club';
import { useClubRequestStore } from '../../js/store/club-request';
import ClubItemEventModal from './ClubItemEventModal.vue';

const $root = getCurrentInstance()?.appContext.config.globalProperties

const itemModal = ref(null)

const clubStore = useClubStore()
const clubRequestStore = useClubRequestStore()

const goPage = async (page) => {
  $root.overlay.loading = true

  clubStore.pageSchedule += page
  if (clubStore.pageSchedule < 1) {
    clubStore.pageSchedule = 1
  }
  if (clubStore.pageSchedule > clubStore.totalPageSchedule) {
    clubStore.pageSchedule = clubStore.totalPageSchedule
  }

  await clubStore.getItemSchedules(clubStore.item.id)

  $root.overlay.loading = false
}

const editItem = async () => {
  $root.overlay.loading = true
  await clubRequestStore.getItem(clubStore.item.id)
  $root.overlay.loading = false

  itemModal.value.open()
}
</script>
<template>
  <div class="flex flex-col w-full gap-1">
    <div class="flex items-center justify-end">
      <button 
        class="p-1 bg-primary text-white rounded hover:opacity-80 group flex items-center gap-1 transition duration-300 delay-75 ease-in-out" 
        @click.prevent="itemModal.open()"
        title="新規追加"
      >
        <PlusIcon class="w-5 h-5 group-hover:scale-110 transition duration-300 delay-75 ease-in-out" />
      </button>
    </div>
    <table class="w-full table">
      <thead class="border-t-2 border-t-secondary border-b border-b-gray-200 bg-gray-100 text-xs">
        <tr>
          <!-- title -->
          <th class="max-w-40">タイトル</th>
          <!-- type -->
          <th>タイプ</th>
          <!-- status -->
          <th>ステータス</th>
          <!-- updated_at -->
          <th>更新日</th>
          <!-- action -->
          <th></th>
        </tr>
      </thead>
      <tbody>
        <template v-if="clubStore.item.schedules && clubStore.item.schedules.length">
          <tr v-for="schedule in clubStore.item.schedules" class="border-b border-b-gray-200">
            <td class="px-1 max-w-40">
              <div class="flex flex-col">
                {{ schedule.name }}
                <div class="text-gray-500 text-xs truncate" :title="schedule.resources">
                  {{ schedule.resources }}
                </div>
              </div>
            </td>
            <td class="px-1">
              <div class="flex flex-col">
                <div>
                  {{ 
                    schedule.repeated === 'none' ? '一回' :
                    schedule.repeated === 'daily' ? '毎日' :
                    schedule.repeated === 'weekly' ? '毎週' :
                    schedule.repeated === 'monthly' ? '毎月' :
                    schedule.repeated === 'annually' ? '毎年' : ''
                  }}
                </div>
                <span v-if="schedule.is_all_day" class="text-xxs w-fit rounded-sm bg-gray-200 text-gray-500 px-1">終日</span>
              </div>
            </td>
            <td class="px-1 text-xs">
              <!-- pending - new -->
              <span 
                v-if="schedule.status === 'pending'" 
                class="rounded-sm flex w-fit text-tertiary bg-gradient-to-r from-tertiary/20 px-1 py-px"
              >
                未処理
              </span>
              <!-- admin replied, waitting client edit -->
              <span 
                v-else-if="schedule.status === 'waitting'" 
                class="rounded-sm flex w-fit text-purple bg-gradient-to-r from-purple/20 px-1 py-px"
              >
                未承認
              </span>
              <!-- approved by admin -->
              <span 
                v-else-if="schedule.status === 'approved'" 
                class="rounded-sm flex w-fit text-green bg-gradient-to-r from-green/20 px-1 py-px"
              >
                承認済
              </span>
              <!-- rejected by admin -->
              <span 
                v-else 
                class="rounded-sm flex w-fit text-error bg-gradient-to-r from-error/20 px-1 py-px"
              >
                却下
              </span>
            </td>
            <td class="px-1 text-xs">
              {{ schedule.updated_at }}
            </td>
            <td class="px-1 text-center">
              <button 
                class="px-1 py-px bg-primary text-white rounded hover:opacity-80 group flex items-center gap-1 transition duration-300 delay-75 ease-in-out text-xs" 
                @click.prevent="editItem"
              >
                編集
              </button>
            </td>
          </tr>
        </template>
        <tr v-else>
          <td colspan="5" class="text-center p-4 text-gray-500 text-xs">スケジュールがありません</td>
        </tr>
      </tbody>
      <!-- pagination -->
      <tfoot v-if="clubStore.totalPageSchedule > 1">
        <tr>
          <td colspan="5" class="p-2">
            <div class="flex items-center justify-between">
              <div>
                <!-- 2671 件中 1-5 を表示 -->
                <span class="text-xs text-gray-500">
                  {{ clubStore.item.totalSchedules }} 件中 {{ clubStore.showingFromSchedule }}-{{ clubStore.showingToSchedule }} を表示
                </span>
              </div>

              <div class="flex items-center gap-2">
                <button 
                  class="px-2 py-1 bg-white border text-gray-500 rounded hover:opacity-80 group flex items-center gap-1 transition duration-300 delay-75 ease-in-out"
                  :disabled="clubStore.pageSchedule <= 1"
                  @click.prevent="goPage(-1)"
                >
                  <ChevronLeftIcon class="w-4 h-4" 
                    :class="{
                      'text-gray-300': clubStore.pageSchedule <= 1
                    }"
                  />
                </button>
                <button 
                  class="px-2 py-1 bg-white border text-gray-500 rounded hover:opacity-80 group flex items-center gap-1 transition duration-300 delay-75 ease-in-out"
                  :disabled="clubStore.pageSchedule >= clubStore.totalPageSchedule"
                  @click.prevent="goPage(1)"
                >
                  <ChevronRightIcon class="w-4 h-4" 
                    :class="{
                      'text-gray-300': clubStore.pageSchedule >= clubStore.totalPageSchedule
                    }"
                  />
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
  <ClubItemEventModal ref="itemModal" @saved="goPage(0)" />
</template>
