<script setup>
import { ref, getCurrentInstance } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from '@headlessui/vue'
import { useRoom } from '../../../js/store/room';

const $root = getCurrentInstance()?.appContext.config.globalProperties

const roomStore = useRoom()

const isOpen = ref(false)
const open = async () => {
  isOpen.value = true
}
const close = () => {
  isOpen.value = false
}

const save = async() => {
  $root.overlay.loading = true
  try {
    const response = await axios.post('/api/classrooms', roomStore.item);
    if(response.status == 200) {
      $root.push.success(response.data.message)
      roomStore.item =  [];
      roomStore.getItems();
      close();
    } else {
      $root.push.error(response.data.message)
      $root.overlay.loading = false
    }
  } catch (error) {
    $root.push.error(error.message)
    $root.overlay.loading = false
  }
  $root.overlay.loading = false
}

defineExpose({ open, close })
</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-xl transform bg-white text-left align-middle shadow-xl transition-all border-t-2 border-t-secondary"
            >
              <!-- title -->
              <div class="border-b-gray-200 bg-gray-100 p-2">
                <h3 class="font-semibold text-primary-500">
                  {{ roomStore.item.id ? '部屋の編集' : '新規作成' }}
                </h3>
              </div>
              <div class="flex flex-col gap-2 p-4">
                <div class="flex flex-col gap-2">
                  <div class="flex flex-col w-full">
                    <label class="text-sm text-gray-500">部屋名</label>
                    <input type="text" v-model="roomStore.item.name" class="w-full px-2 py-1 border border-gray-200 rounded-md" />
                  </div>

                  <div class="flex flex-col w-full">
                    <label class="text-sm text-gray-500">場所</label>
                    <input type="text" v-model="roomStore.item.location" class="w-full px-2 py-1 border border-gray-200 rounded-md" />
                  </div>

                  <div class="flex flex-col w-full">
                    <label class="text-sm text-gray-500">説明</label>
                    <textarea v-model="roomStore.item.description" class="w-full px-2 py-1 border border-gray-200 rounded-md" />
                  </div>

                  <!-- manager name + phone -->
                  <div class="flex gap-2 items-center">
                    <div class="flex flex-col w-full">
                      <label class="text-sm text-gray-500">管理者名</label>
                      <input type="text" v-model="roomStore.item.manager_name" class="w-full px-2 py-1 border border-gray-200 rounded-md" />
                    </div>
                    <div class="flex flex-col w-full">
                      <label class="text-sm text-gray-500">電話番号</label>
                      <input type="text" v-model="roomStore.item.manager_phone" class="w-full px-2 py-1 border border-gray-200 rounded-md" />
                    </div>
                  </div>

                  <div class="flex flex-col w-full">
                    <!-- note -->
                    <label class="text-sm text-gray-500">備考</label>
                    <textarea v-model="roomStore.item.notes" class="w-full px-2 py-1 border border-gray-200 rounded-md" />
                  </div>
                </div>
                <div class="flex justify-end gap-2 mt-6">
                  <button class="px-4 py-2 border border-gray-200 rounded-md" @click.prevent="close">キャンセル</button>
                  <button class="px-4 py-2 bg-primary text-white rounded-md" @click.prevent="save">保存</button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
