import { defineStore } from 'pinia'

export const useDashboardStore = defineStore('dashboard', {
  state: () => ({
    totals: {
      in: 0,
      out: 0,
      stay: 0,
    },
  }),
  actions: {
    async getTotals() {
      setTimeout(() => {
        this.totals = {
          in: 101,
          out: 48,
          stay: 53,
        }
      }, 1000)
    }
  }
})
