<script setup>
import { ref, getCurrentInstance, onMounted } from 'vue'
import { TrashIcon } from '@heroicons/vue/24/outline'
import { useEmailSendStore } from '../../js/store/email-send'
import APPJSH from '../../js/helper/ConstantHelper'
import EditorContent from '../components/EditorContent.vue'
import SendEmailPreviewModal from '../components/SendEmailPreviewModal.vue'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import FormatHelper from '../../js/helper/FormatHelper'
import EmailSendTargets from '../components/EmailSendTargets.vue'
import { DocumentDuplicateIcon } from '@heroicons/vue/24/outline'

const $root = getCurrentInstance()?.appContext.config.globalProperties
const emailSendStore = useEmailSendStore()
const sendEmailPreviewModal = ref(null)
const accept = `.${APPJSH.FILE_EXTENSIONS.join(',.')}`

const handleDate = (val) => {
   // convert to yyyy/mm/dd hh:mm
   emailSendStore.item.date = val
}

const handlePreview = () => {
   if (!emailSendStore.item.targets || emailSendStore.item.targets.length === 0) {
      $root.push.error('配信対象を選択してください')
      return
   }

   if (!emailSendStore.item.subject) {
      $root.push.error('件名を入力してください')
      return
   }

   if (!emailSendStore.item.content_text) {
      $root.push.error('テキストメールを入力してください')
      return
   }

   if (!emailSendStore.item.content_html) {
      $root.push.error('HTMLメールを入力してください')
      return
   }

   sendEmailPreviewModal.value.open()
}

const updateTemplateHandle = () => {
   if (emailSendStore.item.content_text || emailSendStore.item.content_html) {
      const c = confirm('変更内容が破棄されますがよろしいですか？')
      if (!c) {
         return
      }
   }

   emailSendStore.updateTemplate()
}

const handleFileSelect = (e) => {
   // get file
   const file = e.target.files[0]
   if (!file) {
      return
   }

   // check if attachments is empty
   if (!emailSendStore.item.attachments) {
      emailSendStore.item.attachments = []
   }

   // check max attachment count
   if (emailSendStore.item.attachments.length > APPJSH.EMAIL_MAX_ATTACHMENT_COUNT) {
      $root.push.error(`添付ファイルは${APPJSH.EMAIL_MAX_ATTACHMENT_COUNT}個までです。`)
      return
   }

   // check file type allowed
   if (!APPJSH.FILE_EXTENSIONS.includes(file.name.split('.').pop().toLowerCase())) {
      $root.push.error(`添付ファイルは${APPJSH.FILE_EXTENSIONS}のみです。`)
      return
   }

   // check file size
   if (file.size > APPJSH.EMAIL_MAX_ATTACHMENT_SIZE * 1024 * 1024) {
      $root.push.error(`ファイルサイズは${APPJSH.EMAIL_MAX_ATTACHMENT_SIZE}MBまでです。`)
      return
   }

   // add to attachments
   emailSendStore.item.attachments.push(file)

   // clear input
   e.target.value = ''
}

const removeAttachmentHandle = (idx) => {
   const c = confirm('添付ファイルを削除しますか？')
   if (!c) {
      return
   }

   // remove attachment
   emailSendStore.item.attachments.splice(idx, 1)
}

onMounted(async () => {
   $root.overlay.loading = true
   await emailSendStore.getTargets()
   await emailSendStore.getTemplates()
   $root.overlay.loading = false
})

const handleCopy = () => {
  navigator.clipboard.writeText(emailSendStore.item.content_text)
}
</script>
<template>
   <div class="w-full bg-white rounded-md p-4 justify-center">
      <div class="flex flex-col border border-t-2 border-t-secondary w-full h-auto bg-graysp divide-y">
         <EmailSendTargets />
         <div class="flex items-center">
            <div class="flex-none w-44 p-1 h-full">
               配信日時設定
            </div>
            <div class="grow h-full">
               <div class="flex flex-wrap gap-6 items-center bg-white p-2 h-full">
                  <label class="flex items-center gap-1 cursor-pointer">
                     <input 
                        type="radio" 
                        class="w-4 h-4" 
                        :value="APPJSH.EMAIL_SEND_TYPES.NOW" 
                        v-model="emailSendStore.item.type" 
                     />
                     今すぐ配信
                  </label>
                  <label class="flex items-center gap-1 cursor-pointer">
                     <input 
                        type="radio" 
                        class="w-4 h-4" 
                        :value="APPJSH.EMAIL_SEND_TYPES.RESERVE" 
                        v-model="emailSendStore.item.type" 
                     />
                     予約配信
                  </label>

                  <div class="flex items-center gap-2">
                     <VueDatePicker 
                        v-model="emailSendStore.item.date" 
                        time-picker-inline
                        locale="ja"
                        cancelText="キャンセル"
                        selectText="選択"
                        :format="FormatHelper.formatDateTime"
                        placeholder="----/--/-- --:--"
                        :disabled="emailSendStore.item.type === APPJSH.EMAIL_SEND_TYPES.NOW"
                        @update:model-value="handleDate"
                     ></VueDatePicker>
                  </div>
               </div>
            </div>
         </div>

         <div class="flex items-center">
            <div class="flex-none w-44 p-1 h-full">
               添付ファイルの追加
            </div>
            <div class="grow h-full">
               <div class="flex flex-wrap gap-4 items-start bg-white p-1 h-full">
                  <div class="flex flex-col gap-2">
                     <label class="px-4 py-1 text-white bg-tertiary rounded cursor-pointer hover:opacity-80 font-semibold w-fit">
                        ファイルを選択する
                        <input 
                           type="file" 
                           class="hidden" 
                           :accept="accept"
                           @change="handleFileSelect($event)" 
                        />
                     </label>
                     <!-- file name or preview -->
                     <div 
                        v-if="emailSendStore.item.attachments && emailSendStore.item.attachments.length" 
                        class="flex flex-col"
                     >
                        <div 
                           v-for="(attachment, idx) in emailSendStore.item.attachments" 
                           class="flex items-center gap-2"
                        >
                           <div class="underline">{{ attachment.name }}</div>
                           <TrashIcon 
                              class="w-4 h-4 text-error cursor-pointer hover:opacity-80 hover:scale-110 transition duration-300 ease-in-out"
                              title="削除"
                              @click="removeAttachmentHandle(idx)"
                           />
                        </div>
                     </div>
                  </div>
                  <span class="text-xxs text-gray-400 pt-1.5">
                     ※ファイルサイズは{{ APPJSH.EMAIL_MAX_ATTACHMENT_SIZE }}MBまでです。
                  </span>
               </div>
            </div>
         </div>

         <div class="flex items-center">
            <div class="flex-none w-44 p-1 h-full">
               件名
            </div>
            <div class="grow h-full">
               <div class="bg-white p-1 h-full">
                  <input 
                     type="text" 
                     class="w-full px-2 py-1 border rounded bg-yellow" 
                     v-model="emailSendStore.item.subject"
                  />
               </div>
            </div>
         </div>

         <div class="flex items-center">
            <div class="flex-none w-44 p-1 h-full">
               本文
            </div>
            <div class="grow h-full bg-white">
               <div class="flex flex-col divide-y">
                  <!-- template -->
                  <div class="flex items-center gap-2 p-1">
                     <div class="w-fit flex-none">テンプレート</div>
                     <div class="grow">
                        <select 
                           v-model="emailSendStore.item.template_id"
                           class="px-2 py-1 border rounded bg-yellow w-full" 
                           @change="updateTemplateHandle"
                        >
                           <option :value="0">選択してください</option>
                           <option 
                              v-for="template in emailSendStore.templates" 
                              :value="template.id"
                           >{{ template.name }}</option>
                        </select>
                     </div>
                     <div class="w-fit flex-none">
                        <button 
                           type="button"
                           class="px-4 py-1 bg-primary text-white rounded hover:opacity-80 font-semibold"
                           @click.prevent="updateTemplateHandle"
                        >更新</button>
                     </div>
                  </div>
                  <div class="p-1 h-full flex gap-2 items-start">
                     <div class="flex flex-col w-1/2">
                        <label class="font-semibold">テキストメール</label>
                        <div class="flex w-full h-96 overflow-auto group relative">
                           <DocumentDuplicateIcon 
                              class="absolute top-2 right-2 h-6 w-6 text-gray-400 cursor-pointer hidden group-hover:flex z-10 hover:text-slate-900" 
                              @click.prevent="handleCopy"
                           />
                           <textarea 
                              class="w-full min-h-full px-2 py-1 border rounded bg-yellow" 
                              v-model="emailSendStore.item.content_text"
                           />
                        </div>
                     </div>
                     <div class="flex flex-col w-1/2">
                        <div class="flex items-center gap-2">
                           <label class="font-semibold">HTMLメール</label>
                           <span class="text-error text-xxs">※テキストメールも必ず設定して下さい。</span>
                        </div>
                        <div class="flex w-full h-96 border">
                           <EditorContent 
                              v-model:content="emailSendStore.item.content_html" 
                              class="flex flex-col w-full h-82 bg-yellow overflow-y-auto" 
                           />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div class="flex items-center">
            <div class="flex-none w-44 p-1 h-full">
               プレビュー
            </div>
            <div class="grow h-full">
               <div class="bg-white p-1 h-full flex flex-col items-center">
                  <button type="button"
                     class="px-6 py-1 bg-primary text-white rounded hover:opacity-80 font-semibold"
                     @click.prevent="handlePreview"
                  >メールプレビュー</button>
               </div>
            </div>
         </div>
      </div>
   </div>
   <SendEmailPreviewModal ref="sendEmailPreviewModal" />
</template>
