<script setup>
import { onMounted, getCurrentInstance } from 'vue'
import { useClubStore } from '../../js/store/club'
import { useClubRequestStore } from '../../js/store/club-request';
import TableClub from '../components/TableClub.vue';

const $root = getCurrentInstance()?.appContext.config.globalProperties
const clubStore = useClubStore()
const clubRequestStore = useClubRequestStore()

onMounted(async () => {
   clubStore.getManagers()
   
   $root.overlay.loading = true
   await clubStore.getItems()
   $root.overlay.loading = false

   clubRequestStore.getResources()
})

</script>
<template>
   <div class="w-full bg-white rounded-md p-4 flex justify-center">
      <TableClub />
   </div>
</template>
