<script setup>
import { ref,  getCurrentInstance, computed } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'
import { PhoneIcon } from '@heroicons/vue/24/outline';
import { useClubStore } from '../../js/store/club';
import APPJSH from '../../js/helper/ConstantHelper';
import ClubItemMember from './ClubItemMember.vue';
import { useAuthStore } from '../../js/store/auth';
import ClubItemSchedule from './ClubItemSchedule.vue';

const $root = getCurrentInstance()?.appContext.config.globalProperties
const clubStore = useClubStore()
const authStore = useAuthStore()

const selectedTab = ref(0)

const isOpen = ref(false)
const open = async (item) => {
  clubStore.isSaved = false;
  selectedTab.value = 0

  if (item && item.id) {
    $root.overlay.loading = true
    // await both of the above finish
    await Promise.all([
      clubStore.getItem(item.id),
      clubStore.getItemSchedules(item.id)
    ])
    $root.overlay.loading = false
  } else {
    clubStore.resetItem()
  }

  isOpen.value = true
}
const close = () => {
  clubStore.searchMemberText = ''
  
  isOpen.value = false
}

const save = async () => {
  clubStore.isSaved = true 

  if (!clubStore.item.name) {
    $root.push.error('クラブ名を入力してください')
    return
  }

  if (!clubStore.item.teacher) {
    $root.push.error('担当者を入力してください')
    return
  }

  $root.overlay.loading = true
  const msg = await clubStore.saveItem()
  $root.overlay.loading = false

  $root.push.info(msg)

  close()
}

const changeTab = (index) => {
  if (index == 1 && clubStore.item.id == 0) {
    $root.push.error('クラブを保存してください')
    return
  }

  selectedTab.value = index
}

const isClubManager = computed(() => {
  return authStore.user && 
    authStore.user.role.includes(APPJSH.ROLES.CLUB) && 
    !authStore.user.role.includes(APPJSH.ROLES.SCHOOL)
})

defineExpose({ open, close })
</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-xl transform bg-white text-left align-middle shadow-xl transition-all rounded-xl"
            >
              <div class="flex flex-col">
                <div class="p-4">
                  <div class="flex w-full mb-4 flex-col">
                    クラブ名
                    <input 
                      v-model="clubStore.item.name" 
                      type="text" 
                      class="w-full px-2 py-1 font-semibold text-xl border rounded-md transition duration-300 delay-75 ease-in-out" 
                      :class="{
                        'bg-red/10 border-red': clubStore.isSaved && clubStore.item.name.trim() === ''
                      }"
                    />
                  </div>

                  <div class="flex items-center gap-2 w-full">
                    <div class="w-1/2 flex flex-col">
                      担当者
                      <input 
                        v-model="clubStore.item.teacher" 
                        type="text" 
                        class="w-full px-2 py-1 border border-gray-200 rounded-md" 
                        :class="{
                          'bg-red/10 border-red': clubStore.isSaved && !clubStore.item.teacher
                        }"
                      />
                    </div>
                    <div class="flex flex-col">
                      電話番号
                      <div class="flex items-center relative">
                        <PhoneIcon class="w-5 h-5 text-primary absolute pt-0.5 pl-2" />
                        <input 
                          v-model="clubStore.item.teacher_phone" 
                          type="text" 
                          class="w-full pl-6 pr-2 py-1 border border-gray-200 rounded-md" 
                          placeholder="電話番号"
                        />
                      </div>
                    </div>
                  </div>

                  <!-- manager -->
                  <div 
                    v-if="!isClubManager"
                    class="flex flex-col mt-2"
                  >
                    管理者
                    <div class="flex w-fit">
                      <select 
                        v-model="clubStore.item.manager" 
                        class="w-full px-2 py-1 border border-gray-200 rounded-md"
                      >
                        <option value="0">未選択</option>
                        <option 
                          v-for="manager in clubStore.managers" 
                          :value="manager.id"
                        >{{ manager.name }}</option>
                      </select>
                    </div>
                  </div>

                  <!-- note -->
                  <div class="flex flex-col mt-2">
                    備考
                    <div>
                      <textarea 
                        v-model="clubStore.item.note" 
                        class="w-full px-2 py-1 border border-gray-200 rounded-md" 
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div v-if="clubStore.item.id" class="flex flex-col px-4">
                  <TabGroup :selectedIndex="selectedTab" @change="changeTab">
                    <TabList as="div" class="flex items-center border-b border-b-gray-200 gap-px">
                      <!-- Schedule for room and resource -->
                      <Tab 
                        class="px-4 py-0.5 transition duration-300 delay-75 ease-in-out rounded-t-lg"
                        :class="{
                          'font-semibold bg-gray-200': selectedTab === 0,
                          'bg-grey-100': selectedTab !== 0
                        }"
                      >
                        スケジュール
                      </Tab>
                      <!-- Members -->
                      <Tab
                        class="px-4 py-0.5 transition duration-300 delay-75 ease-in-out rounded-t-lg"
                        :class="{
                          'font-semibold bg-gray-200': selectedTab === 1,
                          'bg-grey-100': selectedTab !== 1
                        }"
                      >メンバー</Tab>
                    </TabList>

                    <TabPanels>
                      <TabPanel>
                        <div class="flex flex-col gap-2 mt-4">
                          <ClubItemSchedule />
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div class="flex flex-col gap-2 mt-4">
                          <ClubItemMember />
                        </div>
                      </TabPanel>
                    </TabPanels>
                  </TabGroup>
                </div> 

                <!-- actions -->
                <div class="flex gap-4 justify-end p-4">
                  <button
                    class="px-4 py-1 bg-white border text-gray-500 rounded hover:opacity-80 group flex items-center gap-1 transition duration-300 delay-75 ease-in-out"
                    @click.prevent="close"
                  >
                    キャンセル
                  </button>
                  <button
                    class="px-4 py-1 bg-primary text-white rounded hover:opacity-80 group flex items-center gap-1 transition duration-300 delay-75 ease-in-out min-w-28 justify-center"
                    @click.prevent="save"
                  >
                    保存
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
