<script setup>
import { ref,  getCurrentInstance } from 'vue'
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel
} from '@headlessui/vue'
import { useAccountStore } from '../../js/store/account';
const $root = getCurrentInstance()?.appContext.config.globalProperties

const accountStore = useAccountStore()

const isOpen = ref(false)
const open = async (item) => {
  accountStore.setItem(JSON.parse(JSON.stringify(item)))

  isOpen.value = true
}
const close = () => {
  isOpen.value = false
}
const addUser = async() => {
  $root.overlay.loading = true
  try {
    const response = await axios.post('/api/users', accountStore.item);
    if(response.status == 200) {
      $root.push.success(response.data.message)
      accountStore.item =  {role: [], active: 1};
      accountStore.getItems();
      close();
    } else {
      $root.push.error(response.data.message)
      $root.overlay.loading = false
    }
  } catch (error) {
    console.error('Error adding user:', error);
    
  }
  $root.overlay.loading = false
}
const updateUser = async() => {
  $root.overlay.loading = true
  try {
    const response = await axios.put(`/api/users/${accountStore.item.id}`, accountStore.item);
    if(response.status == 200) {
      $root.push.success(response.data.message)
      accountStore.item =  {role: [], active: 1};
      accountStore.getItems();
      close();
    } else {
      $root.push.error(response.data.message)
    }
  } catch (error) {
    console.error('Error adding user:', error);
  }
  $root.overlay.loading = false
}
defineExpose({ open, close })
</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-md transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all rounded-lg p-4"
            >
              <div class="mt-2 flex flex-col gap-2">
                <div class="flex flex-col">
                  <label>表示名 <span class="text-red font-bold">(*)</span></label>
                  <input v-model="accountStore.item.name" type="text" class="w-full px-2 py-1 border border-gray-200 rounded-md" />
                </div>
                <div class="flex flex-col">
                  <label>ログインID <span class="text-red font-bold">(*)</span></label>
                  <input v-model="accountStore.item.login_id" type="text" class="w-full px-2 py-1 border border-gray-200 rounded-md" />
                </div>
                <div class="flex flex-col">
                  <label>パスワード <span v-show="!accountStore.item.id" class="text-red font-bold">(*)</span></label>
                  <input v-model="accountStore.item.password" type="text" class="w-full px-2 py-1 border border-gray-200 rounded-md" />
                </div>
                <div class="flex flex-col">
                  <label>メールアドレス<span class="text-red font-bold">(*)</span></label>
                  <input v-model="accountStore.item.email" type="text" class="w-full px-2 py-1 border border-gray-200 rounded-md" />
                </div>
                <div class="flex flex-col">
                  <label>権限</label>
                  <div class="flex items-center gap-2 flex-wrap">
                    <label 
                      v-for="(value, key) in  accountStore.roles"
                      class="flex items-center gap-1 px-2 py-1 rounded bg-white cursor-pointer"
                    >
                      <input v-model="accountStore.item.role" type="checkbox" :value="key" />
                      <span>{{ value }}</span>
                    </label>
                  </div>
                </div>
                <div class="flex flex-col">
                  <label>ログイン状態</label>
                  <div class="flex items-center gap-2 flex-wrap px-1 py-1">
                    <label class="gap-1">
                      <input
                        type="radio"
                        value="1"
                        v-model="accountStore.item.active"
                      />
                      有効
                    </label>
                    <label class="flex gap-1">
                      <input
                        type="radio"
                        value="0"
                        v-model="accountStore.item.active"
                      />
                      無効
                    </label>
                  </div>
                </div>
                <div class="flex items-center gap-4 justify-center">
                  
                  <button
                    v-if="!accountStore.item.id"
                    type="button" 
                    class="p-2 rounded-md bg-primary text-white hover:opacity-80 min-w-20"
                    @click="addUser"
                  >
                    登録する
                  </button>
                  <button v-else
                    type="button" 
                    class="p-2 rounded-md bg-primary text-white hover:opacity-80 min-w-20"
                    @click="updateUser"
                  >
                  更新
                  </button>
                  <button 
                    type="button" 
                    class="p-2 rounded-md bg-gray-100 text-gray-800 hover:opacity-80"
                    @click="close"
                  >
                    キャンセル
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
