<script setup>
import { ref, getCurrentInstance } from 'vue'
import { useEmailHistoryStore } from '../../js/store/email-history';
import { PaperClipIcon } from '@heroicons/vue/24/outline';
import APPJSH from '../../js/helper/ConstantHelper';
import DateTimeHelper from '../../js/helper/DatetimeHelper';
import EmailHistoryItemModal from './EmailHistoryItemModal.vue';
import TablePagination from './TablePagination.vue';

const $root = getCurrentInstance()?.appContext.config.globalProperties
const emailHistoryStore = useEmailHistoryStore()
const emailHistoryItemModal = ref(null)
</script>
<template>
  <div class="w-full p-4 bg-white rounded-md">
    <table class="w-full table">
      <thead class="border-t-2 border-t-secondary border-b border-b-gray-200 bg-gray-100 text-xs">
        <tr>
          <th 
            v-for="header in emailHistoryStore.headers" 
            class="font-normal p-2 text-left"
          >
            {{ header.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="emailHistoryStore.items.length">
          <tr 
            v-for="item in emailHistoryStore.items" 
            class="border-b border-b-gray-200 cursor-pointer hover:bg-gray-50 transition duration-300 delay-75 ease-in-out"
            @click.prevent="emailHistoryItemModal.open(item.id)"
          >
            <td class="p-2">{{ item.id }}</td>
            <td class="p-2">
              {{ DateTimeHelper.format(item.send_at, 'YYYY/MM/DD(ddd)　HH:mm') }}
            </td>
            <td class="p-2">
              <div class="flex items-center flex-wrap gap-1">
                <div v-for="target in item.targets" class="rounded border border-primary px-2 bg-primary/10">
                  {{ target === 'all' ? '全学年' : target.replace(/-/g, ' ・ ') }}
                </div>
              </div>
            </td>
            <td class="p-2">
              <div 
                class="py-0.5 px-2 text-white text-xs font-bold w-fit rounded-full"
                :class="{
                  'bg-tertiary': item.status === 'pending',
                  'bg-error': item.status === 'error',
                  'bg-green': item.status === 'success',
                }"
              >
                {{ APPJSH.EMAIL_STATUS[item.status] }}
              </div>
            </td>
            <td class="p-2 text-pblue font-bold hover:opacity-80 hover:underline w-96">
              <p class="flex items-center gap-1 truncate overflow-auto w-96" :title="item.subject">
                <PaperClipIcon class="h-4 w-4" v-if="item.attachments && item.attachments.length" />
                {{ item.subject }}
              </p>
            </td>
          </tr>
        </template>
        <tr v-else-if="!$root.overlay.loading">
          <td class="p-2 text-center text-grey text-xs" :colspan="emailHistoryStore.headers.length">データがありません</td>
        </tr>
      </tbody>
    </table>

    <div class="mt-2">
      <TablePagination 
        :total="emailHistoryStore.total" 
        v-model:currentPage="emailHistoryStore.page"
        :perPage="emailHistoryStore.perPage"
        :onPageChange="emailHistoryStore.getItems"
      />
    </div>
  </div>

  <EmailHistoryItemModal ref="emailHistoryItemModal" />
</template>
