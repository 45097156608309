<script setup>
import { getCurrentInstance } from 'vue'
import { useClubRequestStore } from '../../../js/store/club-request';

const $root = getCurrentInstance()?.appContext.config.globalProperties
const clubRequestStore = useClubRequestStore()

const applyFilter = async () => {
  $root.overlay.loading = true
  if (clubRequestStore.filter.rooms.length > 2) {
    clubRequestStore.filter.rooms = clubRequestStore.filter.rooms.filter(room => room !== 0)
  }

  if (clubRequestStore.filter.resources.length > 2) {
    clubRequestStore.filter.resources = clubRequestStore.filter.resources.filter(resource => resource !== 0)
  }

  await clubRequestStore.getItems()
  $root.overlay.loading = false
}

const clearFilterRoom = () => {
  clubRequestStore.filter.rooms = [0]
}

const clearFilterResource = () => {
  clubRequestStore.filter.resources = [0]
}
</script>
<template>
  <div class="flex flex-col w-full gap-2 h-auto max-h-full overflow-y-auto">
    <div class="flex items-center">
      <!-- apply filter -->
      <button 
        class="bg-blue-500 text-white rounded px-2 py-1.5 w-full"
        @click.prevent="applyFilter"
      >適用</button>
    </div>
    <!-- Rooms -->
    <div v-if="clubRequestStore.rooms.length" class="flex flex-col">
      <div class="flex items-center justify-between">
        <h4 class="font-semibold">部屋</h4>
        <!-- check all/clear toogle -->
        <div class="flex items-center gap-1 text-xxs">
          <button 
            class="text-blue-500"
            @click.prevent="clubRequestStore.filter.rooms = clubRequestStore.rooms.map(room => room.id)"
          >全選択</button>
          /
          <button 
            class="text-error" 
            @click.prevent="clearFilterRoom"
          >クリア</button>
        </div>
      </div>

      <label 
        v-for="room in clubRequestStore.rooms"
        class="flex items-center gap-1 text-gray-500"
      >
        <input 
          type="checkbox" 
          :value="room.id" 
          class="w-4 h-4" 
          v-model="clubRequestStore.filter.rooms" 
        />
        <span>{{ room.name }}</span>
      </label>
    </div>

    <!-- Resource -->
    <div v-if="clubRequestStore.resources.length" class="flex flex-col">
      <div class="flex items-center justify-between">
        <h4 class="font-semibold">リソース</h4>
        <!-- check all/clear toogle -->
        <div class="flex items-center gap-1 text-xxs">
          <button 
            class="text-blue-500"
            @click.prevent="clubRequestStore.filter.resources = clubRequestStore.resources.map(resource => resource.id)"
          >全選択</button>
          /
          <button 
            class="text-error"
            @click.prevent="clearFilterResource"
          >クリア</button>
        </div>
      </div>

      <label 
        v-for="resource in clubRequestStore.resources"
        class="flex items-center gap-1 text-gray-500"
      >
        <input 
          type="checkbox" 
          :value="resource.id" 
          class="w-4 h-4" 
          v-model="clubRequestStore.filter.resources"
        />
        <span>{{ resource.name }}</span>
      </label>
    </div>
  </div>
</template>
