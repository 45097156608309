const ValidateHelper = {
  isEmail: (email) => {
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    return pattern.test(email)
  },
  isEmpty: (value) => {
    return value === '' || value === null || value === undefined
  },
  isNumber: (value) => {
    return !isNaN(value)
  },
}

export default ValidateHelper;
