<script setup>
import { ref } from 'vue'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { DocumentDuplicateIcon } from '@heroicons/vue/24/outline';

const editor = ref(null)

const props = defineProps({
  class: {
    type: String,
    default: 'flex h-full w-full',
  },
})

const content = defineModel('content', {
  type: String,
  default: '',
})

const toolbar = [
  [
    {
      header: [1, 2, 3, 4, 5, 6, false]
    }
  ],
  ['bold', 'italic', 'underline'],
  ['blockquote', 'code-block'],
  ['link', 'image', 'video'],
  [{'color': [
    '#000000', '#e60000', '#ff9900', '#ffff00',
    '#008a00', '#0066cc', '#9933ff', '#ffffff',
    '#facccc', '#ffebcc', '#ffffcc', '#cce8cc',
    '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666',
    '#ffc266', '#ffff66', '#66b966', '#66a3e0',
    '#c285ff', '#888888', '#a10000', '#cc5200',
    '#e5b800', '#006100', '#0047b2', '#6b24b2',
    '#444444', '#0c0000', '#663d00', '#995c00',
    '#333300', '#003700', '#002966', '#3d1466',
  ]}],
  [{'align': ['center', 'right', 'justify']}],
  [{ 'indent': '-1'}, { 'indent': '+1' }], 
  [{ 'direction': 'rtl' }],   
  ['clean'],
]

const handleCopy = () => {
  navigator.clipboard.writeText(content.value)
}

const handlePaste = (e) => {
  navigator.clipboard.readText().then(text => {
    editor.value.pasteHTML(text)
  })
}
</script>
<template>
  <div :class="props.class" class="relative group">
    <DocumentDuplicateIcon 
      class="absolute top-2 right-2 h-6 w-6 text-gray-400 cursor-pointer hidden group-hover:flex z-10 hover:text-slate-900" 
      @click.prevent="handleCopy"
    />

    <div
      class="flex flex-col h-full w-full"
      @paste="handlePaste"
    >
      <QuillEditor 
        ref="editor"
        :toolbar="toolbar"
        v-model:content="content" 
        toolbar="full" 
        placeholder="内容を入力してください"
        contentType="html"
        type="html"
        class="flex h-full w-full"
      />
    </div>
  </div>
</template>
