<script setup>
import { ref,  getCurrentInstance } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue'
import { ArrowUpTrayIcon, CalendarIcon } from '@heroicons/vue/24/outline';
import { useMember } from '../../js/store/member';
import { useClubStore } from '../../js/store/club';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const $root = getCurrentInstance()?.appContext.config.globalProperties

const memberStore = useMember()
const clubStore = useClubStore()

const isOpen = ref(false)
const open = async (id, type) => {
  if (!clubStore.item || !clubStore.item.id) {
    $root.push.error('クラブが選択されていません')
    close()
    return
  }

  $root.overlay.loading = true
  await memberStore.getItem(id, clubStore.item.id, type)
  $root.overlay.loading = false

  isOpen.value = true
}
const close = () => {
  isOpen.value = false
}

const save = async () => {
  if (!clubStore.item || !clubStore.item.id) {
    $root.push.error('クラブが選択されていません')
    return
  }

  memberStore.saved = true

  if (!memberStore.member.name) {
    $root.push.error('名前を入力してください')
    return
  }

  if (!memberStore.member.phone) {
    $root.push.error('電話番号を入力してください')
    return
  }

  $root.overlay.loading = true
  try {
    await memberStore.save(clubStore.item.id, 'guest')
    $root.push.success('メンバーを保存しました')

    // update member list
    clubStore.getMembers()

    close()
  } catch (error) {
    $root.push.error('メンバーの保存に失敗しました')
  }
  $root.overlay.loading = false
}

const changeKycHandler = (e) => {
  const file = e.target.files[0]
  if (!file) {
    return
  }

  const reader = new FileReader()
  reader.onload = (e) => {
    memberStore.member.file = e.target.result
  }
  reader.readAsDataURL(file)
}

defineExpose({ open, close })
</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-md transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all rounded-lg p-4"
            >
              <DialogTitle class="text-lg font-semibold">メンバー</DialogTitle>

              <div class="flex flex-col gap-2 mt-2">
                <div class="flex divide-x">
                  <div class="grow">
                    <div class="flex flex-col gap-2 p-2">
                      <div class="flex flex-col">
                        <label for="name" class="text-sm text-gray-500">名前</label>
                        <input 
                          type="text" 
                          id="name" 
                          v-model="memberStore.member.name" 
                          class="w-full px-2 py-1 border rounded" 
                          :class="{
                            'border-red bg-error/10': memberStore.saved && !memberStore.member.name
                          }"
                        />
                      </div>
                      <div class="flex flex-col">
                        <label for="phone" class="text-sm text-gray-500">電話番号</label>
                        <input 
                          type="text" 
                          id="phone" 
                          v-model="memberStore.member.phone" 
                          class="w-full px-2 py-1 border rounded" 
                          :class="{
                            'border-red bg-error/10': memberStore.saved && !memberStore.member.phone
                          }"
                        />
                      </div>
                      <div class="flex flex-col">
                        <label for="email" class="text-sm text-gray-500">メールアドレス</label>
                        <input type="email" id="email" v-model="memberStore.member.email" class="w-full px-2 py-1 border rounded" />
                      </div>

                      <div class="flex gap-2">
                        <div class="flex flex-col w-fit">
                          <label for="email" class="text-sm text-gray-500">性別</label>
                          <div>
                            <select class="w-full px-2 py-0.5 border rounded" v-model="memberStore.member.gender">
                              <option value="male">男性</option>
                              <option value="female">女性</option>
                              <option value="other">その他</option>
                            </select>
                          </div>
                        </div>
                        <div class="flex flex-col w-fit">
                          <label for="dob" class="text-sm text-gray-500">生年月日</label>
                          <div class="max-w-40">
                            <VueDatePicker 
                              v-model="memberStore.member.dob" 
                              locale="ja"
                              format="yyyy-MM-dd"
                              :enable-time-picker="false"
                              auto-apply
                              teleport
                            >
                              <template #input-icon>
                                <CalendarIcon class="ml-2 w-4 h-4" />
                              </template>
                              <template #dp-input="{ value }">
                                <input 
                                  type="text" 
                                  :value="value" 
                                  class="border rounded-md pl-8 pr-2 py-1 max-w-40"
                                />
                              </template>
                            </VueDatePicker>
                          </div>
                        </div>
                      </div>

                      <div class="flex flex-col">
                        <label for="note" class="text-sm text-gray-500">備考</label>
                        <textarea id="note" v-model="memberStore.member.note" class="w-full px-2 py-1 border rounded"></textarea>
                      </div>

                      <!-- expried date -->
                      <div class="flex flex-col">
                        <label for="expired_at" class="text-sm text-gray-500">有効期限</label>
                        <div class="max-w-40">
                          <VueDatePicker 
                            v-model="memberStore.member.expired_at" 
                            locale="ja"
                            format="yyyy-MM-dd"
                            :enable-time-picker="false"
                            auto-apply
                            teleport
                          >
                            <template #input-icon>
                              <CalendarIcon class="ml-2 w-4 h-4" />
                            </template>
                            <template #dp-input="{ value }">
                              <input 
                                type="text" 
                                :value="value" 
                                class="border rounded-md pl-8 pr-2 py-1 max-w-40"
                              />
                            </template>
                          </VueDatePicker>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex-none w-32">
                    <div class="flex flex-col gap-6 p-2">
                      <div class="flex flex-col">
                        <label class="text-sm text-gray-500">QRコード</label>
                        <a v-if="memberStore.member.qr_code_image"
                          :href="memberStore.member.qr_code_image"
                          target="_blank"
                        >
                          <img :src="memberStore.member.qr_code_image" alt="avatar" class="w-32 h-32 object-cover p-1" />
                        </a>
                        <div v-else class="w-32 h-32 bg-gray-100 flex items-center justify-center">
                          <span class="text-gray-400">QRコード</span>
                        </div>
                      </div>
                      
                      <div class="flex flex-col">
                        <div class="flex items-center gap-2">
                          <label class="text-sm text-gray-500">KYC</label>
                          <label class="p-1 bg-primary rounded hover:opacity-80 group flex items-center gap-1 transition duration-300 delay-75 ease-in-out">
                            <!-- just allow upload image png, jpg, jpeg -->
                            <input 
                              type="file" 
                              class="hidden" 
                              accept="image/png, image/jpeg, image/jpg" 
                              @change="changeKycHandler"
                            />
                            <ArrowUpTrayIcon class="w-4 h-4 cursor-pointer text-white font-semibold" />
                          </label>
                        </div>
                        <a v-if="memberStore.member.file || memberStore.member.kyc"
                          :href="memberStore.member.file ? memberStore.member.file : memberStore.member.kyc"
                          target="_blank"
                        >
                          <img 
                            :src="memberStore.member.file ? memberStore.member.file : memberStore.member.kyc" 
                            alt="kyc" 
                            class="w-32 h-32 object-cover p-1" 
                          />
                        </a>
                        <div v-else class="w-32 h-32 bg-gray-100 flex items-center justify-center">
                          <span class="text-gray-400">KYC</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- cancel and save buttons -->
                <div class="flex gap-4 justify-end mt-4">
                  <button
                    class="px-4 py-1 bg-white border text-gray-500 rounded hover:opacity-80 group flex items-center gap-1 transition duration-300 delay-75 ease-in-out"
                    @click.prevent="close"
                  >
                    キャンセル
                  </button>
                  <button
                    class="px-4 py-1 bg-primary text-white rounded hover:opacity-80 group flex items-center gap-1 transition duration-300 delay-75 ease-in-out min-w-28 justify-center"
                    @click.prevent="save"
                  >
                    保存
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
