<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue'
import { useRoom } from '../../../js/store/room';
import { useResource } from '../../../js/store/resource';
import TableRooms from './TableRooms.vue';
import TableResources from './TableResources.vue';
import Calendar from './Calendar.vue';
import RoomModal from './RoomModal.vue';
import ResourceModal from './ResourceModal.vue';
import TableRoomsHeader from './TableRoomsHeader.vue';
import TableResourcesHeader from './TableResourcesHeader.vue';
import EventModal from './EventModal.vue';
import { useClubRequestStore } from '../../../js/store/club-request';

const $root = getCurrentInstance()?.appContext.config.globalProperties
const roomStore = useRoom()
const resourceStore = useResource()
const clubRequestStore = useClubRequestStore()

const roomModal = ref(null)
const resourceModal = ref(null)
const eventModal = ref(null)

const refLeft = ref(null)
const refLeftRoom = ref(null)
const refLeftResource = ref(null)

onMounted(async () => {
  $root.overlay.loading = true

  await clubRequestStore.getResources()
  await roomStore.getItems()
  await resourceStore.getItems()

  $root.overlay.loading = false

  // set height for left column
  const height = refLeft.value.clientHeight
  const h50 = height / 2
  if (refLeftRoom.value.clientHeight > h50) {
    refLeftRoom.value.style.height = `${h50}px`
    refLeftResource.value.style.height = `${h50}px`
  } else {
    refLeftRoom.value.style.height = 'auto'
    // by calculate height of left column
    refLeftResource.value.style.height = `${height - refLeftRoom.value.clientHeight}px`
  }
})

const editRoom = (room) => {
  if (room) {
    roomStore.item = room
  } else {
    roomStore.item = {
      id: 0,
      name: '',
      location: '',
      description: '',
      manager: '',
      phone: '',
      note: '',
      schedule: {
        mode: 'week',
        open: '08:00',
        close: '18:00',
        mode: 'week',
        days: [],
      }
    }
  }
  roomModal.value.open()
}

const editResource = (resource) => {
  if (resource) {
    resourceStore.item = resource
  } else {
    resourceStore.item = {
      id: 0,
      name: '',
      location: '',
      manager: '',
      phone: '',
      note: '',
      schedule: {
        mode: ['week', 'custom'][Math.floor(Math.random() * 2)],
        open: '08:00',
        close: '18:00',
        days: Array.from({ length: 7 }, (_, i) => {
          return Math.random() > 0.5 ? i : -1
        })
      }
    }
  }
  resourceModal.value.open()
}

const addEvent = () => {
  clubRequestStore.item = {
    id: 0,
    club_id: 0,
    name: '',
    rooms: [],
    resources: {},
    date: '',
    start_at: '',
    end_at: '',
    is_all_day: false,
    repeated: 'none',
    end_repeated_type: 'never',
    end_repeat_at: '',
    note: '',
    is_last_day_of_month: false,
  }
  eventModal.value.open()
}

const editEvent = () => {
  eventModal.value.open(clubRequestStore.item.id)
}
</script>
<template>
  <div class="flex flex-col h-full w-full">
    <div class="grid grid-cols-4 gap-4 w-full p-6 h-full">
      <div ref="refLeft" class="flex h-full col-span-1 w-full">
        <div class="gap-4 w-full flex flex-col h-full">
          <div ref="refLeftRoom" class="flex-none max-h-[400px]">
            <div 
              v-if="roomStore.expanded" 
              class="h-auto flex flex-col w-full scroll-smooth max-h-full overflow-y-auto"
            >
              <TableRooms @add="editRoom" />
            </div>
            <TableRoomsHeader v-else @add="editRoom" />
          </div>
          
          <div ref="refLeftResource" class="flex-none h-[400px]">
            <div 
              v-if="resourceStore.expanded" 
              class="h-full flex flex-col w-full scroll-smooth max-h-full overflow-y-auto"
            >
              <TableResources @add="editResource" />
            </div>
            <TableResourcesHeader v-else @add="editResource" />
          </div>
          
        </div>
      </div>
      <div class="flex flex-col h-full col-span-3 overflow-y-auto">
        <Calendar @add="addEvent" @edit="editEvent" />
      </div>
    </div>
  </div>
  <RoomModal ref="roomModal" />
  <ResourceModal ref="resourceModal" />
  <EventModal ref="eventModal" />
</template>
