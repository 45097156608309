<script setup>
import { ref, watch, computed, getCurrentInstance,  } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from '@headlessui/vue'
import { PencilIcon, MinusCircleIcon } from '@heroicons/vue/24/outline';
import { useClubRequestStore } from '../../js/store/club-request';
import { useClubStore } from '../../js/store/club';
import DateTimeHelper from '../../js/helper/DatetimeHelper';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import PopoverResources from '../pages/club-request/PopoverResources.vue';
import PopoverRooms from '../pages/club-request/PopoverRooms.vue';

const $root = getCurrentInstance()?.appContext.config.globalProperties
const clubRequestStore = useClubRequestStore()
const clubStore = useClubStore()

const emit = defineEmits(['saved'])

const date = ref('')
const startAt = ref('')
const endAt = ref('')
const endRepeatAt = ref('')

const isOpen = ref(false)
const open = async (id) => {
  if (!id) {
    // get current time
    date.value = DateTimeHelper.getCurrentDate()
    startAt.value = DateTimeHelper.getCurrentTime()
    endAt.value = DateTimeHelper.getCurrentTimeEnd()
    endRepeatAt.value = DateTimeHelper.getCurrentDate()

    clubRequestStore.reset()
  } else {
    date.value = DateTimeHelper.format(clubRequestStore.item.start_at, 'YYYY-MM-DD')
    startAt.value = DateTimeHelper.format(clubRequestStore.item.start_at, 'HH:mm')
    endAt.value = DateTimeHelper.format(clubRequestStore.item.end_at, 'HH:mm')
    endRepeatAt.value = DateTimeHelper.format(clubRequestStore.item.end_repeat_at, 'YYYY-MM-DD')
  }

  clubRequestStore.item.club_id = clubStore.item.id

  isOpen.value = true
}
const close = () => {
  isOpen.value = false
}

const save = async () => {
  if (!clubRequestStore.item.name) {
    $root.push.error('タイトルを入力してください')
    return
  }

  // require room or resource
  if (!clubRequestStore.item.rooms.length && !clubRequestStore.item.resources.length) {
    $root.push.error('部屋またはリソースを追加してください')
    return
  }

  // check quantity > quantity of resource
  if (Object.values(clubRequestStore.item.resources).length) {
    for (const resource of clubRequestStore.item.resources) {
      const item = clubRequestStore.resources.find(item => item.id === resource.id)
      if (resource.quantity > item.quantity) {
        $root.push.error(`「${item.name}」の数量が不足しています`)
        return
      }
    }
  }

  date.value = DateTimeHelper.format(date.value, 'YYYY-MM-DD')

  clubRequestStore.item.start_at = `${date.value} ${startAt.value}:00`
  clubRequestStore.item.end_at = `${date.value} ${endAt.value}:00`

  if (clubRequestStore.item.end_repeated_type === 'date') {
    clubRequestStore.item.end_repeat_at = DateTimeHelper.format(endRepeatAt.value, 'YYYY-MM-DD')
  }

  $root.overlay.loading = true
  await clubRequestStore.save()
  $root.push.success('保存しました')
  emit('saved')
  $root.overlay.loading = false

  close()
}

const format = computed(() => {
  return 'yyyy年MM月dd日'
})

// watch clubRequestStore.item.is_last_day_of_month
watch(() => clubRequestStore.item.is_last_day_of_month, (value) => {
  if (value) {
    date.value = DateTimeHelper.getLastDayOfMonth(date.value)
  }
})

defineExpose({ open, close })
</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-2xl transform bg-white text-left align-middle shadow-xl transition-all border-t-2 border-t-secondary"
            >
              <!-- title -->
              <div class="border-b-gray-200 bg-gray-100 p-2">
                <h3 class="font-semibold text-primary-500 flex items-center gap-1">
                  <PencilIcon class="w-4 h-4 inline-block" />
                  予約申請
                </h3>
              </div>
              <div class="flex flex-col gap-2 p-4">
                <div class="flex flex-col gap-2 p-4 w-full">
                  <!-- title -->
                  <div class="flex items-center gap-2">
                    <div class="flex-none w-28 text-right">タイトル</div>
                    <div class="grow">
                      <div class="max-w-96">
                        <input v-model="clubRequestStore.item.name" 
                          type="text" 
                          class="px-2 py-1 border border-gray-200 rounded w-full" 
                        />
                      </div>
                    </div>
                  </div>
                  
                  <!-- add room -->
                  <div class="flex items-start gap-2">
                    <div class="flex-none w-28 text-right">
                      <div class="flex items-center gap-1 w-full justify-end">
                        部屋追加
                        <PopoverRooms />
                      </div>
                    </div>
                    <div class="grow">
                      <div 
                        v-if="clubRequestStore.item.rooms && clubRequestStore.item.rooms.length" 
                        class="flex flex-col divide-y max-h-32 overflow-y-auto"
                      >
                        <div 
                          v-for="room in clubRequestStore.item.rooms" 
                          class="flex items-center bg-gray-50 py-1"
                        >
                          <div class="flex-none w-6">
                            <MinusCircleIcon 
                              @click.prevent="clubRequestStore.removeRoom(room)"
                              class="w-5 h-5 text-error cursor-pointer" 
                            />
                          </div>
                          <div class="grow">{{ clubRequestStore.getRoomNameById(room) }}</div>
                        </div>
                      </div>
                      <div 
                        v-else 
                        class="rounded-sm flex items-center min-w-fit w-2/3 bg-gradient-to-r from-tertiary/20 p-1"
                      >
                        <div class="text-gray-500 text-xs">部屋を追加してください</div>
                      </div>
                    </div>
                  </div>

                  <!-- add resource -->
                  <div class="flex items-start gap-2">
                    <div class="flex-none w-28 text-right">
                      <div class="flex items-center gap-1 w-full justify-end">
                        リソース追加
                         <PopoverResources />
                      </div>
                    </div>
                    <div class="grow">
                      <div 
                        v-if="clubRequestStore.item.resources && clubRequestStore.item.resources.length" 
                        class="flex flex-col w-full divide-y max-h-32 overflow-y-auto"
                      >
                        <div 
                          v-for="resource in clubRequestStore.item.resources" 
                          class="flex items-center bg-gray-50 py-1"
                        >
                          <div class="flex-none w-6">
                            <MinusCircleIcon 
                              @click.prevent="clubRequestStore.removeResource(resource.id)"
                              class="w-5 h-5 text-error cursor-pointer" 
                            />
                          </div>
                          <div class="grow">
                            <div class="flex items-center gap-2">
                              {{ clubRequestStore.getResourceNameById(resource.id) }}
                              <div class="text-xs flex items-center">
                                <span>{{ clubRequestStore.getResourceQtyById(resource.id) }} ({{ clubRequestStore.getResourceUnitById(resource.id) }})</span>
                              </div>
                            </div>
                          </div>
                          <!-- qty -->
                          <div class="flex-none w-24 px-1">
                            <div class="flex items-center gap-1">
                              <input 
                                type="number" 
                                v-model.number="resource.quantity" 
                                class="px-1 py-px border border-gray-200 rounded w-16" 
                                min="1"
                                step="1"
                              />
                              <span class="text-xs text-gray-500">{{ clubRequestStore.getResourceUnitById(resource.id) }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div 
                        v-else 
                        class="rounded-sm flex items-center min-w-fit w-2/3 bg-gradient-to-r from-tertiary/20 p-1"
                      >
                        <div class="text-gray-500 text-xs">リソースを追加してください</div>
                      </div>
                    </div>
                  </div>

                  <!-- schedule -->
                  <div class="flex items-start gap-2">
                    <div class="flex-none w-28 text-right py-1">活動日</div>
                    <div class="grow">
                      <div class="flex flex-col gap-2">
                        <div class="flex items-center gap-4 flex-wrap">
                          <VueDatePicker 
                            v-model="date" 
                            auto-apply 
                            :format="format"
                            :enable-time-picker="false"
                            locale="ja"
                            class="mydatepicker w-fit"
                            :hide-input-icon="true"
                            :clearable="false"
                            input-class-name="bg-gray-100 rounded w-32 px-2 py-1 cursor-pointer"
                            :disabled="clubRequestStore.item.is_last_day_of_month"
                          >
                            <template #dp-input="{ value, onInput, onEnter, onTab, onClear, onBlur, onKeypress, onPaste, isMenuOpen }">
                              <input type="text" :value="value" class="bg-gray-100 rounded w-32 px-2 py-1 cursor-pointer" />
                            </template>
                          </VueDatePicker>
                          
                          <div v-if="!clubRequestStore.item.is_all_day" class="flex items-center gap-1">
                            <input type="time" v-model="startAt" class="bg-gray-100 rounded w-20 p-1 cursor-pointer" />
                            <div>〜</div>
                            <input type="time" v-model="endAt" class="bg-gray-100 rounded w-20 p-1 cursor-pointer" />
                          </div>
                        </div>
                        
                        <div class="flex flex-col gap-2">
                          <div class="flex items-center gap-2">
                            <!-- is all day checkbox -->
                            <label class="flex items-center gap-1 cursor-pointer w-fit">
                              <input 
                                type="checkbox" 
                                class="cursor-pointer w-4 h-4" 
                                v-model="clubRequestStore.item.is_all_day" 
                              />
                              <span>終日</span>
                            </label>
                            <select 
                              class="rounded px-2 py-1 bg-gray-100" 
                              v-model="clubRequestStore.item.repeated"
                            >
                              <option value="none">繰り返し</option>
                              <option value="daily">毎日</option>
                              <option value="weekly">毎週</option>
                              <option value="monthly">毎月</option>
                              <option value="annually">毎年</option>
                            </select>
                            <!-- show day of week text help. Example: Every week at Sunday from 13:00 to 15:00 -->
                            <div v-if="clubRequestStore.item.repeated === 'weekly'">
                              <span>毎週</span>
                              <span>{{ DateTimeHelper.format(date, 'ddd') }}</span>
                            </div>
                            <!-- show day of month -->
                            <div v-if="clubRequestStore.item.repeated === 'monthly' || clubRequestStore.item.repeated === 'annually'">
                              <span>毎月</span>
                              <span>
                                {{ DateTimeHelper.format(date, clubRequestStore.item.repeated === 'monthly' ? 'D' : 'D MMM') }}
                              </span>
                            </div>
                            <!-- option for last of month -->
                            <label 
                              v-if="clubRequestStore.item.repeated === 'monthly' || clubRequestStore.item.repeated === 'annually'" 
                              class="flex items-center gap-1"
                            >
                              <input 
                                type="checkbox" 
                                v-model="clubRequestStore.item.is_last_day_of_month" 
                                value="1"
                                class="cursor-pointer w-4 h-4"
                              />
                              <span>月末</span>
                            </label>
                          </div>

                          <!-- type repeated {none,daily,weekly,monthly,annually} -->
                          <div 
                            v-if="clubRequestStore.item.repeated !== 'none'" 
                            class="flex items-center gap-2 w-fit"
                          >
                            <!-- end repeated -->
                            <div class="flex items-center gap-2">
                              <div class="flex items-center gap-1">
                                終了
                                <select 
                                  class="rounded px-2 py-1 bg-gray-100" 
                                  v-model="clubRequestStore.item.end_repeated_type"
                                >
                                  <option value="never">無期限</option>
                                  <option value="date">日付</option>
                                </select>
                              </div>
                              <VueDatePicker 
                                v-if="clubRequestStore.item.end_repeated_type === 'date'"
                                v-model="endRepeatAt" 
                                auto-apply 
                                :format="format"
                                :enable-time-picker="false"
                                locale="ja"
                                class="mydatepicker w-fit"
                                :hide-input-icon="true"
                                :clearable="false"
                                input-class-name="bg-gray-100 rounded w-32 px-2 py-1 cursor-pointer"
                              >
                                <template #dp-input="{ value, onInput, onEnter, onTab, onClear, onBlur, onKeypress, onPaste, isMenuOpen }">
                                  <input type="text" :value="value" class="bg-gray-100 rounded w-32 px-2 py-1 cursor-pointer" />
                                </template>
                              </VueDatePicker>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- note -->
                  <div class="flex items-start gap-2">
                    <div class="flex-none w-28 text-right">備考</div>
                    <div class="grow">
                      <div class="max-w-96">
                        <textarea 
                          v-model="clubRequestStore.item.note" 
                          class="px-2 py-1 border border-gray-200 rounded w-full"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex justify-between gap-2 mt-2">
                  <!-- approve button -->
                  <div class="flex items-center gap-2">
                    <!-- status -->
                    <div v-if="clubRequestStore.item.id > 0">
                      <!-- pending - new -->
                      <span 
                        v-if="clubRequestStore.item.status === 'pending'" 
                        class="rounded-sm flex w-fit text-tertiary bg-gradient-to-r from-tertiary/20 px-2 py-1"
                      >
                        未処理
                      </span>
                      <!-- admin replied, waitting client edit -->
                      <span 
                        v-else-if="clubRequestStore.item.status === 'waitting'" 
                        class="rounded-sm flex w-fit text-purple bg-gradient-to-r from-purple/20 px-2 py-1"
                      >
                        未承認
                      </span>
                      <!-- approved by admin -->
                      <span 
                        v-else-if="clubRequestStore.item.status === 'approved'" 
                        class="rounded-sm flex w-fit text-green bg-gradient-to-r from-green/20 px-2 py-1"
                      >
                        承認済
                      </span>
                      <!-- rejected by admin -->
                      <span 
                        v-else 
                        class="rounded-sm flex w-fit text-error bg-gradient-to-r from-error/20 px-2 py-1"
                      >
                        却下
                      </span>
                    </div>
                  </div>
                  <div class="flex items-center gap-2">
                    <button 
                      class="px-4 py-1 border border-gray-200 rounded" 
                      @click.prevent="close"
                    >キャンセル</button>
                    <button 
                      class="px-4 py-1 bg-blue-500 text-white rounded" 
                      @click.prevent="save"
                    >保存</button>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
