import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import 'dayjs/locale/en'
import 'dayjs/locale/ja'
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(timezone)
dayjs.extend(customParseFormat);

// set timezone to Ho Chi Minh
dayjs.tz.setDefault(import.meta.env.VITE_APP_TIMEZONE)

// set locale to ja
dayjs.locale('ja')

const DateTimeHelper = {
  format(date, format) {
    if (!date) {
      return ''
    }

    if (date.length === 10) {
      date = `${date} 23:59:59`
    }

    if (!format) {
      format = 'YYYY-MM-DD'
    }

    return dayjs(date).format(format)
  },
  convert2JpDate(date) {
    const dt = dayjs(date, "YYYY-MM-DD").isValid() ? dayjs(date, "YYYY-MM-DD") : dayjs(date, "DD-MM-YYYY")
    return dayjs(dt).format('YYYY年MM月DD日');
  },
  // compare local time: today vs date
  isBeforeToday(date) {
    return dayjs(date).isBefore(dayjs())
  },
  getToday() {
    // get in vi locale
    return dayjs().format('YYYY-MM-DD')
  },
  isToday(date) {
    return dayjs(date).isSame(dayjs(), 'day')
  },
  convertToDateWithDayOfWeek(date) {
    return dayjs(date).locale('ja').format('YYYY年MM月DD日(ddd)')
  },
  addDays(date, days) {
    // days can be negative or positive
    return dayjs(date).add(days, 'day').format('YYYY-MM-DD')
  },
  // count days of month
  getDaysOfMonth(date) {
    return dayjs(date).daysInMonth()
  },
  // get current time with format 15:14
  getCurrentDate() {
    return dayjs().format('YYYY-MM-DD')
  },
  getCurrentTime() {
    return dayjs().format('HH:mm')
  },
  getCurrentTimeEnd() {
    return dayjs().add(2, 'hour').format('HH:mm')
  },
  getLastDayOfMonth(date) {
    return dayjs(date).endOf('month').format('YYYY-MM-DD')
  }
}

export default DateTimeHelper
