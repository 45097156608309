<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue'
import TableImport from '../../components/TableImport.vue';
import ImportModal from '../../components/ImportModal.vue';
import { useImportStore } from '../../../js/store/import';

const importStore = useImportStore()
const $root = getCurrentInstance()?.appContext.config.globalProperties

const tableImport = ref(null)

onMounted(async () => {
  $root.overlay.loading = true
  await importStore.getItems()
  $root.overlay.loading = false
})
</script>
<template>
  <div class="flex flex-col w-full gap-2  p-4">
    <div class="flex justify-end">
      <button 
        class="px-4 py-1 bg-primary shadow text-white rounded hover:opacity-80"
        @click.prevent="tableImport.open()"
      >
        インポート
      </button>
      <button 
       class="ml-2 px-4 py-1 bg-orange-700 shadow text-white rounded hover:opacity-80"
      ><a href="storage/template/sample_import.xlsx" download="データサンプルファイル.xlsx">サンプルファイル</a>
      </button>
    </div>
    <TableImport />
  </div>
  <ImportModal ref="tableImport" />
</template>
