import { defineStore } from 'pinia'
import DatimeHelper from '../helper/DatetimeHelper'

export const useResourceRequest = defineStore('resource-request', {
  state: () => ({
    // date for pagination: is today with format 2024年8月1日
    date: DatimeHelper.getToday(),
    hours: {
      // filter: for show in view
      start: 0,
      // filter: for show in view
      end: 23,
      // from 0 to 23
      hours: Array.from({ length: 24 }, (_, i) => i),
    },
    items: [],
  }),
  actions: {
    changeDay(v) {
      // add 1 or -1 to day
      this.date = DatimeHelper.addDays(this.date, v)
    },
    async getItems() {
      // fake requests
      this.items = Array.from({ length: 20 }, (_, i) => ({
        id: i + 1,
        name: `Requested Title ${i + 1}`,
        // random in [room, resource]
        type: Math.random() > 0.5 ? 'room' : 'resource',
        club: 'Club Name',
        location: 'Location Name',
        approved: Math.random() > 0.5,
        user: 'User Name',
        start: parseFloat(`${Math.floor(Math.random() * 6) + 6}.${Math.floor(Math.random() * 8)}`),
        end: parseFloat(`${Math.floor(Math.random() * 6) + 12}.${Math.floor(Math.random() * 8)}`),
        expanded: false,
      }))
    },
    checkDuplicate(item) {
      return false
    }
  },
})
