<script setup>
import { ref, getCurrentInstance } from 'vue'
import { PencilIcon, TrashIcon } from '@heroicons/vue/24/outline';
import { useAccountStore } from '../../js/store/account';
import AccountItemModal from './AccountItemModal.vue';

const $root = getCurrentInstance()?.appContext.config.globalProperties
const accountStore = useAccountStore()

const refAccountModal = ref(null)      
</script>
<template>
  <div class="flex flex-col w-full gap-2 p-4">
    <div class="flex justify-start">
      <button 
        class="px-4 py-1 bg-primary shadow text-white rounded hover:opacity-80"
        @click.prevent="refAccountModal.open(accountStore.item)"
      >
        新規登録 
      </button>
    </div>
    <div class="w-full bg-white rounded-md">
      <table class="w-full table">
        <thead class="border-t-2 border-t-secondary border-b border-b-gray-200 bg-gray-100 text-xs">
          <tr>
            <th v-for="header in accountStore.headers" class="font-normal p-2 text-left">{{ header.text }}</th>
            <th class="font-normal p-2 text-left">操作</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="accountStore.items.length">
            <tr v-for="item in accountStore.items" class="border-b border-b-gray-200">
              <td class="p-2">{{ item.id }}</td>
              <td class="p-2">{{ item.name }}</td>
              <td class="p-2">{{ item.email }}</td>
              <td class="p-2">{{ item.login_id }}</td>
              <td class="p-2">
                <div class="flex items-center gap-px">
                  <span v-for="i in 8" class="flex w-2 h-2 rounded-full bg-black"></span>
                </div>
              </td>
              <td class="p-2">{{ item.role.map(role => accountStore.roles[role] || role).join(', ') }}</td>
              <td class="p-2">{{ item.active == 1 ? '有効':'無効'}}</td>
              <td>
                <div class="flex gap-2 items-center">
                  <button 
                    type="button" 
                    class="p-1.5 rounded-full bg-green group hover:opacity-80"
                    @click.prevent="refAccountModal.open(item)"
                  >
                    <PencilIcon class="w-4 h-4 text-white group-hover:scale-110 transition-all delay-75 duration-300 ease-in-out" />
                  </button>
                </div>
              </td>
            </tr>
          </template>
          <tr v-else-if="!$root.overlay.loading">
            <td class="p-2 text-center text-grey text-xs" :colspan="accountStore.headers.length">データがありません</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <AccountItemModal ref="refAccountModal" />
</template>
